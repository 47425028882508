import { GongPublicAuth } from './gong.types';
import { Roles } from './user.types';

export enum OrganizationStatus {
  ACTIVE = 'ACTIVE',
  TRIAL = 'TRIAL',
  ARCHIVED = 'ARCHIVED',
}

/** Organization as it is used across the app. */
export type AppOrganization = {
  id: string;
  name: string;
  // Date fields.
  createdAt?: Date;
  renewalDate?: Date;
  updatedAt?: Date;
  // Number of seats.
  adminSeats?: number;
  managerSeats?: number;
  repSeats?: number;
  // Enable/disable features.
  isHiringModuleEnabled?: boolean;
  isUploadModalEnabled?: boolean;
  isSelfServiceEnabled?: boolean;
  isTeamsEnabled?: boolean;
  userSeats?: Record<Roles, number>;
  // Status of the organization.
  status?: OrganizationStatus;
  // Gong auth.
  gongAuth?: GongPublicAuth;
};

/** Organization as it comes from the API. */
export type DBOrganization = {
  id: string;
  name: string;
  displayName: string;
  createdAt: Date;
  renewalDate: Date;
  updatedAt: Date;
  repSeats: number;
  adminSeats: number;
  managerSeats: number;
  isHiringModuleEnabled: boolean;
  isUploadModalEnabled: boolean;
  isSelfServiceEnabled: boolean;
  isTeamsEnabled: boolean;
  userSeats: Record<Roles, number>;
  gongAuth: GongPublicAuth;
  status: OrganizationStatus;
};

export type OrganizationResponse = {
  message: string;
  data: {
    organization: DBOrganization;
  };
};

export interface OrganizationsResponse {
  message: string;
  data: {
    organizations: DBOrganization[];
  };
}
