import { DropdownPlacement, TextColor } from '../../../types';
import { Typography, TypographySize } from '../Typography';

type BaseTag = { id: string; name: string };

interface MoreLabelProps {
  hiddenTags: BaseTag[];
}

const MoreTagsLabel = ({ hiddenTags }: MoreLabelProps) => {
  return (
    <Typography
      size={TypographySize.CAPTION}
      color={TextColor.SECONDARY}
      noWrap
      tooltip={{
        content: (
          <div className="flex flex-col">
            {hiddenTags.map((tag) => (
              <span key={tag.id}>• {tag.name}</span>
            ))}
          </div>
        ),
        placement: DropdownPlacement.BOTTOM,
      }}
    >
      +{hiddenTags.length} more
    </Typography>
  );
};

export default MoreTagsLabel;
