import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';
import {
  getYesNoOptions,
  RadioGroup,
  Slider,
  TextArea,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../../../components';
import {
  ComponentSize,
  QuestionType,
  ScorecardAnswer,
  ScorecardQuestionType,
  ScorecardSectionType,
  TextColor,
} from '../../../../types';
import CallScorecardActionButtons from './CallScorecardActionButtons';

interface CallScorecardSectionsProps {
  /** Array of sections in the scorecard */
  sections: ScorecardSectionType[];
  /** Function to clear all answers */
  handleClearAnswers: () => void;
  /** Function to set the scorecard answers */
  setAnswers: Dispatch<SetStateAction<ScorecardAnswer[] | undefined>>;
  /** Flag indicating if the user can edit the scorecard */
  canUserEdit: boolean;
  /** Flag indicating if the scorecard is AI scoreable */
  isAiScoreable: boolean;
  /** ID of the scorecard */
  scorecardId?: string;
  /** ID of the scorecard template */
  scorecardTemplateId?: string;
  /** Name of the scorecard template */
  scorecardName?: string;
  /** Array of current scorecard answers */
  answers?: ScorecardAnswer[];
}

const CallScorecardSections = ({
  scorecardId,
  scorecardTemplateId,
  scorecardName,
  sections,
  answers,
  setAnswers,
  canUserEdit,
  isAiScoreable,
  handleClearAnswers,
}: CallScorecardSectionsProps) => {
  // Handles changing the answer for a question
  const handleAnswerChange = (questionId: string, value: string) => {
    // Check if user have permission to edit scorecard
    if (!canUserEdit) return;

    setAnswers((prevAnswers) => {
      // If there are no previous answers, create a new array with the current answer
      if (!prevAnswers) return [{ questionId, response: value }];

      let answerFound = false;
      // Map through existing answers, updating the matching question or keeping others as is
      const updatedAnswers = prevAnswers.map((answer) => {
        if (answer.questionId === questionId) {
          answerFound = true;
          return { ...answer, response: value }; // Update existing answer
        }
        return answer; // Keep other answers unchanged
      });

      // If no matching question was found, add the new answer to the array
      if (!answerFound) {
        updatedAnswers.push({ questionId, response: value });
      }

      // Return the updated answers array
      return updatedAnswers;
    });
  };

  // Retrieves the answer for a specific question
  const getAnswerForQuestion = (questionId: string) => {
    return answers?.find((answer) => answer.questionId === questionId)?.response || '';
  };

  const renderQuestionInput = (question: ScorecardQuestionType) => {
    const answer = getAnswerForQuestion(question.id);

    switch (question.type) {
      case QuestionType.RANGE:
        return (
          <Slider
            size={ComponentSize.MEDIUM}
            value={Number(answer) || 1}
            onChange={(value) => handleAnswerChange(question.id, value.toString())}
          />
        );

      case QuestionType.YES_NO:
        return (
          <RadioGroup
            options={getYesNoOptions(true)}
            value={answer}
            isViewMode={!canUserEdit}
            onChange={(e) => handleAnswerChange(question.id, e.target.value)}
          />
        );

      case QuestionType.TEXT:
      default:
        return (
          <>
            {canUserEdit && (
              <TextArea
                value={answer}
                onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                placeholder="Type here"
              />
            )}
            {!canUserEdit && <Typography color={TextColor.SECONDARY}>{answer}</Typography>}
          </>
        );
    }
  };

  return (
    <div className={clsx('flex h-full flex-grow flex-col justify-between gap-8', !canUserEdit && 'pb-4')}>
      <div className="flex flex-col gap-6">
        {sections?.map((section) => {
          // Filter questions if in view mode to only show answered ones
          const questionsToDisplay = canUserEdit
            ? section.questions
            : section.questions.filter((question) => getAnswerForQuestion(question.id));

          // Only show section if it has questions to display
          if (!questionsToDisplay.length) return null;

          return (
            <div key={section.id} className="flex flex-col gap-2">
              <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD} color={TextColor.SECONDARY}>
                {section.title}
              </Typography>
              <div className="flex flex-col gap-6">
                {questionsToDisplay.map((question) => (
                  <div className="flex flex-col gap-2" key={question.id}>
                    <Typography size={TypographySize.H5}>
                      {question.text}
                      {/* We explicitly check for number because it can be 0 */}
                      {typeof question.points === 'number' && (
                        <span className="text-base text-neutral-content">
                          &nbsp;({question.points} point{Math.abs(question.points) === 1 ? '' : 's'})
                        </span>
                      )}
                    </Typography>
                    {renderQuestionInput(question)}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      {canUserEdit && (
        <CallScorecardActionButtons
          handleClearAnswers={handleClearAnswers}
          scorecardId={scorecardId}
          scorecardTemplateId={scorecardTemplateId}
          scorecardName={scorecardName}
          answers={answers}
          isAiScoreable={isAiScoreable}
        />
      )}
    </div>
  );
};

export default CallScorecardSections;
