import { useCallback, useMemo } from 'react';
import { MIN_REQUIRED_SCENARIO_ITEMS } from '../constants';
import { Permissions, ProspectPageTabs } from '../types';
import { conditionalArray, isValueFilled } from '../utils';
import useAppSelector from './useAppSelector';

/**
 * Hook that returns a function to check if required fields are filled for a given prospect tab.
 * Used to determine if a tab can be navigated to based on whether required fields in previous tabs are filled.
 * If no tab is provided, it checks if all required fields are filled.
 * @returns Function that takes a tab name and returns whether its required fields are filled
 */
const useCheckRequiredProspectFields = () => {
  const fields = useAppSelector((state) => state.prospect.fields);

  const user = useAppSelector((state) => state.auth.user);
  const isSuperAdmin = (user?.permissions || []).includes(Permissions.ADMIN_ACCESS);

  const arePersonalFieldsFilled = useMemo(() => {
    const requiredFields = [
      fields.firstName.value,
      fields.lastName.value,
      fields.personaVoiceId.value,
      fields.firstMessage.value,
    ];
    return requiredFields.every(isValueFilled);
  }, [fields.firstName, fields.lastName, fields.personaVoiceId, fields.firstMessage]);

  const areLeadFieldsFilled = useMemo(() => {
    return isValueFilled(fields.jobTitle.value);
  }, [fields.jobTitle]);

  const areAccountFieldsFilled = useMemo(() => {
    const requiredFields = [fields.accountName.value, fields.industry.value];
    return requiredFields.every(isValueFilled);
  }, [fields.accountName, fields.industry]);

  const areScenarioFieldsFilled = useMemo(() => {
    const filledPriorities = fields.priorities.filter((priority) => isValueFilled(priority.value));
    const filledObjections = fields.objections.filter((objection) => isValueFilled(objection.value));
    const filledQuestions = fields.questions.filter((question) => isValueFilled(question.value));
    return (
      isValueFilled(fields.successResult.value) &&
      filledPriorities.length >= MIN_REQUIRED_SCENARIO_ITEMS &&
      filledObjections.length >= MIN_REQUIRED_SCENARIO_ITEMS &&
      filledQuestions.length >= MIN_REQUIRED_SCENARIO_ITEMS
    );
  }, [fields.priorities, fields.objections, fields.questions, fields.successResult]);

  const areConfigurationFieldsFilled = useMemo(() => {
    // All tuning fields are only available to super-admins.
    if (!isSuperAdmin) return true;

    const requiredFields = [
      ...conditionalArray(fields.isHangupFeatureEnabled, fields.hangupPrompt),
      fields.ttsModelId,
      fields.sttModelId,
      fields.llmModel,
      fields.llmModelId,
      fields.llmTemperature,
      fields.llmMaxTokens,
      fields.promptTemplateId,
    ];

    return requiredFields.every(isValueFilled);
  }, [
    isSuperAdmin,
    fields.isHangupFeatureEnabled,
    fields.hangupPrompt,
    fields.ttsModelId,
    fields.sttModelId,
    fields.llmModel,
    fields.llmModelId,
    fields.llmTemperature,
    fields.llmMaxTokens,
    fields.promptTemplateId,
  ]);

  const areRequiredFieldsFilled = useCallback(
    (tab?: ProspectPageTabs, checkOnlyCurrentTab = false) => {
      if (checkOnlyCurrentTab) {
        switch (tab) {
          case ProspectPageTabs.PERSONAL:
            return arePersonalFieldsFilled;
          case ProspectPageTabs.LEAD:
            return areLeadFieldsFilled;
          case ProspectPageTabs.ACCOUNT:
            return areAccountFieldsFilled;
          case ProspectPageTabs.SCENARIO:
            return areScenarioFieldsFilled;
          case ProspectPageTabs.CONFIGURATION:
            return areConfigurationFieldsFilled;
          default:
            return true;
        }
      }

      switch (tab) {
        case ProspectPageTabs.PERSONAL:
          return arePersonalFieldsFilled;
        case ProspectPageTabs.LEAD:
          return arePersonalFieldsFilled && areLeadFieldsFilled;
        case ProspectPageTabs.ACCOUNT:
          return arePersonalFieldsFilled && areLeadFieldsFilled && areAccountFieldsFilled;
        case ProspectPageTabs.SCENARIO:
          return arePersonalFieldsFilled && areLeadFieldsFilled && areAccountFieldsFilled && areScenarioFieldsFilled;
        case ProspectPageTabs.CONFIGURATION:
        default:
          return (
            arePersonalFieldsFilled &&
            areLeadFieldsFilled &&
            areAccountFieldsFilled &&
            areScenarioFieldsFilled &&
            areConfigurationFieldsFilled
          );
      }
    },
    [
      arePersonalFieldsFilled,
      areLeadFieldsFilled,
      areAccountFieldsFilled,
      areScenarioFieldsFilled,
      areConfigurationFieldsFilled,
    ]
  );

  return areRequiredFieldsFilled;
};

export default useCheckRequiredProspectFields;
