import clsx from 'clsx';
import React from 'react';
import { ButtonColor, ButtonVariant } from '../Button';
import TextButton from '../Button/TextButton/TextButton';
import { ButtonGroupProps } from './ButtonGroup.types';

const ButtonGroup = ({ children, className, disabled, loading, size }: ButtonGroupProps) => {
  return (
    <div className={clsx('flex flex-row-reverse gap-2', className)}>
      {React.Children.map(children, (child, index) => {
        // If the button group is loading,
        // disable all buttons and only show the loading state on the first button.
        const isDisabled = (index !== 0 && loading) || disabled;
        const isLoading = index === 0 && loading;

        // Determine the button style type based on its index in the array.
        let variant = ButtonVariant.GHOST;
        if (index === 0) {
          variant = ButtonVariant.FILLED;
        } else if (index === 1 && children.length > 2) {
          variant = ButtonVariant.OUTLINE;
        }

        return (
          <TextButton
            {...child.props}
            size={child.props.size ?? size}
            variant={child.props.variant ?? variant}
            color={child.props.color ?? ButtonColor.SECONDARY}
            loading={child.props.loading ?? isLoading}
            disabled={child.props.disabled ?? isDisabled}
          />
        );
      })}
    </div>
  );
};

export default ButtonGroup;
