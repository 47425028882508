import clsx from 'clsx';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { ComponentSize, DropdownPlacement, TextColor } from '../../../../types';
import { ButtonColor, ButtonVariant, IconButton } from '../../Button';
import { Dot } from '../../Dot';
import { Dropdown, DropdownContent, DropdownTrigger } from '../../Dropdown';
import { Icon } from '../../Icons';
import { InputError, TextInput } from '../../Input';
import { Tooltip } from '../../Tooltip';
import { Typography } from '../../Typography';
import { SELECT_DROPDOWN_MAX_HEIGHT, SELECT_ICON_SIZE } from './BaseSelect.constants';
import { BaseSelectProps } from './BaseSelect.types';
import BaseSelectOption from './BaseSelectOption';
import BaseSelectSearch from './BaseSelectSearch';

/**
 * BaseSelect is a reusable dropdown component that can be used for both single-select and multi-select.
 * It is used internally by the SingleSelect and MultiSelect components.
 */
const BaseSelect = forwardRef<HTMLDivElement, BaseSelectProps>(
  (
    {
      customButton,
      displayValue,
      displayValueColor,
      options,
      clearable,
      controlledOpenProps,
      disabled,
      error,
      inline,
      loading,
      maxOptionsHeight = SELECT_DROPDOWN_MAX_HEIGHT,
      multiSelect,
      placeholder,
      searchProps,
      selected,
      size = ComponentSize.SMALL,
      width,
      tooltip,
      handleClear,
      handleOptionSelection,
      onClose,
    },
    ref
  ) => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);

    const isOpen = controlledOpenProps ? controlledOpenProps.isOpen : uncontrolledIsOpen;
    const setIsOpen = controlledOpenProps ? controlledOpenProps.setIsOpen : setUncontrolledIsOpen;

    const listRef = useRef<HTMLDivElement[]>([]);

    // If there are no options to render and create is enabled, we should render the create option.
    const optionsToRender =
      !options.length && !!searchProps?.createOption && !!searchProps?.searchValue?.length
        ? [searchProps.createOption]
        : options;

    // If there are no options to render, we should disable the dropdown
    // unless it has a search field.
    const canOpenEmpty = !optionsToRender.length && !searchProps;
    const shouldDisable = disabled || loading || canOpenEmpty;

    const runOptionClick = (value: string) => {
      handleOptionSelection(value);
      if (!multiSelect) {
        setIsOpen(false);
      }
    };

    const renderEndElement = () => {
      // Multi-select only shows the first selected option,
      // so we show a +(N-1) button to indicate there are more selected options.
      const showMultiSelectCount = Array.isArray(selected) && selected.length > 1;
      // Optional clear button to clear all selected options.
      const showClearButton = clearable && !disabled;
      return (
        <div className="flex items-center">
          {showMultiSelectCount && (
            <div className="flex h-6 w-6 items-center justify-center">
              <Typography color={TextColor.SECONDARY}>+{selected.length - 1}</Typography>
            </div>
          )}
          {showClearButton && (
            <Tooltip label="Clear">
              <IconButton
                icon={Icon.CLOSE}
                variant={ButtonVariant.GHOST}
                color={ButtonColor.SECONDARY}
                size={SELECT_ICON_SIZE[size]}
                onClick={handleClear}
              />
            </Tooltip>
          )}
          {/* Toggle button to open/close the dropdown. */}
          <IconButton
            icon={isOpen ? Icon.CHEVRON_UP : Icon.CHEVRON_DOWN}
            size={SELECT_ICON_SIZE[size]}
            onClick={() => setIsOpen((prev) => !prev)}
            variant={ButtonVariant.GHOST}
            color={ButtonColor.SECONDARY}
            disabled={shouldDisable}
          />
        </div>
      );
    };

    // Resets the hovered index whenever the dropdown opens
    // or the length of the options change (ie. due to filtering).
    useEffect(() => {
      if (!isOpen) return;

      const firstSelectedValue = (Array.isArray(selected) ? selected[0] : selected)?.value;
      const firstSelectedIndex = optionsToRender.findIndex((option) => firstSelectedValue === option.value);
      setHoveredIndex(firstSelectedIndex === -1 ? null : firstSelectedIndex);

      // Update the listRef to only include the options that are currently rendered.
      listRef.current = listRef.current.slice(0, options.length);
    }, [isOpen, options.length]);

    // Clears the search value when the dropdown opens.
    useEffect(() => {
      if (isOpen && searchProps?.searchValue?.length) {
        searchProps.setSearchValue('');
      }
    }, [isOpen]);

    // Runs onClose only when the dropdown goes from open to closed.
    const prevIsOpenRef = useRef(isOpen);
    useEffect(() => {
      if (prevIsOpenRef.current && !isOpen && onClose) {
        onClose();
      }
      prevIsOpenRef.current = isOpen;
    }, [isOpen, onClose]);

    const renderContent = () => (
      <div className="flex flex-col gap-1">
        {searchProps && <BaseSelectSearch {...searchProps} />}
        {!!optionsToRender.length && (
          <div
            className="display-scrollbar-sm flex flex-col gap-1 overflow-y-auto"
            style={{
              maxHeight: maxOptionsHeight,
            }}
          >
            {optionsToRender.map((option, index) => (
              <BaseSelectOption
                key={index}
                option={option}
                index={index}
                size={size}
                multiSelect={multiSelect}
                runOptionClick={runOptionClick}
                listRef={listRef}
                hovered={inline ? false : hoveredIndex === index}
              />
            ))}
          </div>
        )}
      </div>
    );

    if (inline) {
      return renderContent();
    }

    return (
      <div className={clsx(!width && 'w-full')}>
        <Tooltip label={tooltip} triggerConfig={{ fullWidth: !customButton }}>
          <Dropdown
            fullWidth
            open={isOpen}
            onOpenChange={setIsOpen}
            placement={DropdownPlacement.BOTTOM_START}
            disabled={shouldDisable}
            listNavigation={{
              activeIndex: hoveredIndex,
              setActiveIndex: (index) => setHoveredIndex(index === null ? 0 : index),
              listRef,
            }}
          >
            <DropdownTrigger ref={ref} fullWidth={!width}>
              {!customButton && (
                <TextInput
                  placeholder={placeholder}
                  value={displayValue}
                  size={size}
                  endElement={renderEndElement()}
                  startElement={
                    displayValueColor ? <Dot color={displayValueColor} size={ComponentSize.X_SMALL} /> : undefined
                  }
                  disabled={shouldDisable}
                  onClick={() => setIsOpen((prev) => !prev)}
                  error={!!error}
                  width={width}
                />
              )}
              {customButton &&
                React.cloneElement(customButton, {
                  active: isOpen,
                  onClick: () => setIsOpen((prev) => !prev),
                })}
            </DropdownTrigger>
            <DropdownContent>{renderContent()}</DropdownContent>
          </Dropdown>
        </Tooltip>
        {typeof error === 'string' && <InputError message={error} />}
      </div>
    );
  }
);

BaseSelect.displayName = 'BaseSelect';

export default BaseSelect;
