import { useCallback } from 'react';
import { useHandleApiResponse, useToast, useUpdateOrgSeats } from '../../../../../../hooks';
import { useEnableUserMutation } from '../../../../../../services';
import { TableUser } from '../../../../../../types';
import { AlertType } from '../../../../../shared';
import ConfirmModal from '../../../../ConfirmModal';

const ERROR_MSG = 'Failed to enable user';

interface EnableConfirmModalProps {
  user: TableUser;
  closeModal: () => void;
}

const EnableConfirmModal = ({ user, closeModal }: EnableConfirmModalProps) => {
  // Custom hooks
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();
  const updateOrgSeats = useUpdateOrgSeats();

  // Mutations
  const [enableUser, { isLoading }] = useEnableUserMutation();

  const handleReEnableUser = useCallback(async () => {
    try {
      const response = await enableUser({ id: user.id });

      const onSuccess = () => {
        closeModal();
        // Update organization seat counts
        updateOrgSeats(undefined, user.role);
      };

      await handleApiResponse({ response, errorMsg: ERROR_MSG, onSuccess });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [handleApiResponse, closeModal, showToast, user, updateOrgSeats]);

  return (
    <ConfirmModal
      buttonText="Enable"
      isOpen
      setIsOpen={closeModal}
      onConfirm={() => void handleReEnableUser()}
      title="Confirm enable user"
      isLoading={isLoading}
      confirmText={
        <>
          Are you sure you want to re-enable&nbsp;
          <span className="font-medium">{user.name}</span>?
        </>
      }
    />
  );
};

export default EnableConfirmModal;
