/**
 * Type for the transcription submission type.
 */
export enum TranscriptionSubmissionType {
  URL_SUBMISSION = 'URL_SUBMISSION',
  FILE_SUBMISSION = 'FILE_SUBMISSION',
  GONG_CALL_SUBMISSION = 'GONG_CALL_SUBMISSION',
}

/**
 * Type for the payload data for the transcription submission.
 */
export type SubmitAITwinLinkPayload = {
  type: TranscriptionSubmissionType;
  url: string;
};
