export const PROMPT_TEMPLATE_LIQUID_TAGS = [
  'name',
  'email',
  'jobTitle',
  'company',
  'industry',
  'uncoveredGoalsCount',
  'outcome',
  'myersBriggs',
  'personalityTypeDemeanor',
  'difficulty',
  'objectionRate',
  'questionRate',
  'objections',
  'questions',
  'priorities',
  'hiddenContexts',
  'context',
];
