import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertType, Icon, ManageTagsAndNotes } from '../../../components';
import { AppRoutes } from '../../../constants';
import {
  useAppDispatch,
  useAppSelector,
  useFeatureFlag,
  useHandleApiResponse,
  useToast,
  useUpdateProspectTagsAndNotes,
} from '../../../hooks';
import { updateProspect } from '../../../redux/reducers';
import { useReactivatePracticeProspectMutation } from '../../../services';
import { ActivityFilterKeys, LD_FeatureFlags, Permissions, PracticeProspect, ProspectStatus } from '../../../types';
import { conditionalArray } from '../../../utils';

const REACTIVATE_PROSPECT_ERROR_MSG = 'Failed to reactivate prospect';

interface useProspectActionsProps {
  prospect: PracticeProspect;
  closeActions: () => void;
  /** Opens manager notes modal for prospect. */
  setManagerNotesProspect: (prospect: PracticeProspect) => void;
}

const useProspectActions = ({ prospect, closeActions, setManagerNotesProspect }: useProspectActionsProps) => {
  const { associatedPhoneNumber, notes, personaId, status, tags = [] } = prospect || {};
  const isArchived = status === ProspectStatus.ARCHIVED;

  const navigate = useNavigate();

  const [notesInput, setNotesInput] = useState(notes || '');
  const [selectedTags, setSelectedTags] = useState(tags);

  const dispatch = useAppDispatch();
  const { organization, user } = useAppSelector((state) => state.auth);
  const isSelfServiceEnabled = !!organization?.isSelfServiceEnabled;
  const permissions = user?.permissions || [];
  const canManageProspect = permissions.includes(Permissions.MANAGE_PROSPECT);

  const selfServeFF = useFeatureFlag(LD_FeatureFlags.RELEASE_SELF_SERVE);
  const handleApiResponse = useHandleApiResponse();
  const { showToast } = useToast();
  const { runApplyTagsAndNotes, isLoading: isApplyingTags } = useUpdateProspectTagsAndNotes({
    personaId,
    selectedTags,
    notes: notes || '',
    notesInput,
    tags,
    onApplyNotes: () => dispatch(updateProspect({ personaId, notes: notesInput })),
    onApplyTags: () => dispatch(updateProspect({ personaId, tags: selectedTags })),
    setNotesInput,
    setSelectedTags,
  });

  const [reactivatePracticeProspect, { isLoading: isReactivating }] = useReactivatePracticeProspectMutation();

  const canEditProspect = selfServeFF && canManageProspect && isSelfServiceEnabled;

  // Opens the manager notes modal.
  const onOpenDetails = useCallback(() => {
    setManagerNotesProspect(prospect);
    closeActions();
  }, [prospect, closeActions, setManagerNotesProspect]);

  // Navigates to the prospect page.
  const onEditProspect = useCallback(() => {
    navigate(`${AppRoutes.PROSPECT}/${personaId}`);
    closeActions();
  }, [personaId, navigate, closeActions]);

  // Copies the prospect's phone number to the clipboard.
  const onCopyPhoneNumber = useCallback(() => {
    if (!associatedPhoneNumber) return;

    navigator.clipboard.writeText(associatedPhoneNumber);
    showToast({ message: 'Phone number copied to clipboard', type: AlertType.SUCCESS });
    closeActions();
  }, [associatedPhoneNumber, showToast, closeActions]);

  // Copies the prospect's link to the clipboard.
  const onShare = useCallback(() => {
    const url = new URL(window.location.href);

    if (canEditProspect) {
      // If the user is allowed to view/edit the prospect, navigate to the prospect page.
      url.pathname = `${AppRoutes.PROSPECT}/${personaId}`;
    } else {
      // Otherwise, generate a link that filters the activity page to the prospect.
      url.search = '';
      url.searchParams.set(ActivityFilterKeys.PROSPECT, personaId);
    }

    navigator.clipboard.writeText(url.toString());

    showToast({ message: 'Link to prospect copied to clipboard', type: AlertType.SUCCESS });
    closeActions();
  }, [canEditProspect, personaId, showToast, closeActions]);

  const onUnarchive = useCallback(async () => {
    try {
      const response = await reactivatePracticeProspect(personaId);
      handleApiResponse({
        response,
        errorMsg: REACTIVATE_PROSPECT_ERROR_MSG,
        onSuccess: (newData) => {
          dispatch(updateProspect({ ...newData.prospect }));
          closeActions();
        },
      });
    } catch (error) {
      console.error(REACTIVATE_PROSPECT_ERROR_MSG, error);
      showToast({ message: REACTIVATE_PROSPECT_ERROR_MSG, type: AlertType.ERROR });
    }
  }, [personaId, dispatch, handleApiResponse, reactivatePracticeProspect, showToast, closeActions]);

  const activeActions = useMemo(
    () => [
      ...conditionalArray(canEditProspect, {
        label: 'Edit prospect',
        icon: Icon.EDIT,
        onClick: onEditProspect,
      }),
      ...conditionalArray(canManageProspect, {
        label: 'Open details',
        icon: Icon.USER_SEARCH,
        onClick: onOpenDetails,
      }),
      ...conditionalArray(!!associatedPhoneNumber, {
        label: 'Copy phone number',
        icon: Icon.COPY,
        onClick: onCopyPhoneNumber,
      }),
      {
        label: 'Add tags and notes',
        icon: Icon.TAG,
        submenu: (
          <ManageTagsAndNotes
            inline
            isLoading={isApplyingTags}
            notes={notesInput}
            setNotes={setNotesInput}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
        ),
        onSubmenuClose: runApplyTagsAndNotes,
      },
      {
        label: 'Share',
        icon: Icon.SHARE,
        onClick: onShare,
      },
    ],
    [
      canEditProspect,
      isApplyingTags,
      selectedTags,
      onOpenDetails,
      onEditProspect,
      onCopyPhoneNumber,
      onShare,
      runApplyTagsAndNotes,
      onUnarchive,
    ]
  );

  const archivedActions = useMemo(
    () => [
      {
        label: 'Unarchive',
        icon: Icon.UNARCHIVE,
        isLoading: isReactivating,
        onClick: onUnarchive,
      },
    ],
    [isReactivating, onUnarchive]
  );

  return isArchived ? archivedActions : activeActions;
};

export default useProspectActions;
