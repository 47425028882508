import { DatePickerRange } from '../components';
import { AnalyticsFilterKeys } from './filters.types';

export enum AnalyticsTab {
  ACTIVITY = 'activity',
  PROGRESS_REPORT = 'progress-report',
  TEAM_PERFORMANCE = 'team-performance',
}

export enum AverageByPeriod {
  DAY = 'day',
  WEEK = 'week',
}

export enum CallMetric {
  FILLER_WORDS = 'fillerWords',
  TALK_SPEED = 'talkSpeed',
  TALK_RATIO = 'talkRatio',
  LONGEST_MONOLOGUE = 'longestMonologue',
}

// TODO: Extend the AnalyticsFilters type when the API accepts the date as a single value.
type BaseAnalyticsPayload = {
  [AnalyticsFilterKeys.DATE_RANGE]: DatePickerRange[];
  [AnalyticsFilterKeys.PROSPECT]?: string[];
  [AnalyticsFilterKeys.TAGS]?: string[];
};

export type CallsActivityPayload = BaseAnalyticsPayload & {
  [AnalyticsFilterKeys.CALLERS]?: string[];
  [AnalyticsFilterKeys.TEAM]?: string[];
};

type BaseProgressReportsPayload = BaseAnalyticsPayload & {
  [AnalyticsFilterKeys.AVERAGE_BY]: AverageByPeriod;
  [AnalyticsFilterKeys.USER]: string;
  timezone: string;
};

export type ProgressReportCallScorePayload = BaseProgressReportsPayload & {
  scorecardTemplateId: string;
};

export type ProgressReportMetricPayload = BaseProgressReportsPayload & {
  metric: CallMetric;
};

export type CallsActivityData = {
  totalCalls: number;
  totalTalkTime: number;
  callers: {
    id: string;
    name: string;
    callCount: number;
    talkTime: number;
  }[];
};

export type ProgressReportCallScoreData = {
  date: string;
  avg_score: number | null;
}[];

export type ProgressReportMetricData = {
  stats: {
    date: string;
    metricValue: number;
    count: number;
  }[];
  overallAverage: number;
};
