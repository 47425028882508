import { Dispatch, SetStateAction, useCallback } from 'react';
import { useAppDispatch } from '../../../hooks';
import { setOrgId, setProspectType, setScenario } from '../../../redux/reducers';
import { ComponentSize, ProspectScenario, ProspectType } from '../../../types';
import { parseValuesToOptions } from '../../../utils';
import {
  ButtonColor,
  ButtonGroup,
  findOptionByValue,
  Select,
  TextButton,
  Typography,
  TypographySize,
} from '../../shared';

const SCENARIO_OPTIONS = parseValuesToOptions(Object.values(ProspectScenario));

interface SimulationScenarioStepProps {
  goToPrevStep: () => void;
  setNewScenario: Dispatch<SetStateAction<ProspectScenario | undefined>>;
  targetOrgId?: string;
  newProspectPurpose?: ProspectType;
  newScenario?: ProspectScenario;
}

const SimulationScenarioStep = ({
  goToPrevStep,
  setNewScenario,
  targetOrgId,
  newProspectPurpose,
  newScenario,
}: SimulationScenarioStepProps) => {
  const dispatch = useAppDispatch();

  const disabled = !newScenario;

  const onNext = useCallback(() => {
    if (!targetOrgId || !newProspectPurpose || !newScenario) return;

    dispatch(setOrgId(targetOrgId));
    dispatch(setProspectType(newProspectPurpose));
    dispatch(setScenario(newScenario));
  }, [targetOrgId, newProspectPurpose, newScenario, dispatch]);

  return (
    <div className="flex flex-col gap-4">
      <Typography size={TypographySize.H5}>What kind of scenario are you simulating?</Typography>
      <Select
        placeholder="Select scenario"
        options={SCENARIO_OPTIONS}
        selected={findOptionByValue(SCENARIO_OPTIONS, newScenario)}
        onChange={(value) => setNewScenario(value as ProspectScenario)}
      />
      <ButtonGroup size={ComponentSize.MEDIUM}>
        <TextButton onClick={onNext} text="Next" color={ButtonColor.PRIMARY} disabled={disabled} />
        <TextButton onClick={goToPrevStep} text="Back" />
      </ButtonGroup>
    </div>
  );
};

export default SimulationScenarioStep;
