import { useCallback, useState } from 'react';
import { useAppDispatch, useHandleApiResponse, useToast } from '../../../hooks';
import { deleteProspect, updateProspect } from '../../../redux/reducers';
import {
  useArchivePracticeProspectMutation,
  useDeletePracticeProspectMutation,
  useEditPracticeProspectMutation,
} from '../../../services';
import { PracticeProspect } from '../../../types';
import { AlertType, ButtonColor, ButtonGroup, ButtonVariant, TextButton } from '../../shared';
import ConfirmModal from '../ConfirmModal';

const ARCHIVE_ERROR_MSG = 'Failed to archive prospect';
const DELETE_ERROR_MSG = 'Failed to delete prospect';
const EDIT_ERROR_MSG = 'Failed to update prospect';

interface ManagerNotesActionButtonsProps {
  notesInput: string;
  prospect: PracticeProspect;
  onClose: () => void;
  scorecardTemplateIdInput?: string;
}

const ManagerNotesActionButtons = ({
  notesInput,
  prospect,
  onClose,
  scorecardTemplateIdInput,
}: ManagerNotesActionButtonsProps) => {
  const dispatch = useAppDispatch();

  const [isArchiveConfirmModalOpen, setIsArchiveConfirmModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);

  const [archivePracticeProspect, { isLoading: isArchiving }] = useArchivePracticeProspectMutation();
  const [deletePracticeProspect, { isLoading: isDeleting }] = useDeletePracticeProspectMutation();
  const [editPracticeProspect, { isLoading: isSaving }] = useEditPracticeProspectMutation();

  const handleApiResponse = useHandleApiResponse();
  const { showToast } = useToast();

  const { firstName, lastName, isUsed, personaId } = prospect;
  const fullName = `${firstName} ${lastName}`;

  const runArchive = useCallback(async () => {
    try {
      const response = await archivePracticeProspect(personaId);
      handleApiResponse({
        response,
        errorMsg: ARCHIVE_ERROR_MSG,
        onSuccess: (newData) => {
          dispatch(updateProspect({ ...newData.prospect }));
          onClose();
        },
      });
    } catch (error) {
      console.error(error);
      showToast({ type: AlertType.ERROR, message: ARCHIVE_ERROR_MSG });
    }
  }, [personaId, archivePracticeProspect, dispatch, handleApiResponse, onClose, showToast]);

  const runDelete = useCallback(async () => {
    try {
      const response = await deletePracticeProspect(personaId);
      handleApiResponse({
        response,
        errorMsg: DELETE_ERROR_MSG,
        onSuccess: () => {
          dispatch(deleteProspect(personaId));
          onClose();
        },
      });
    } catch (error) {
      console.error(error);
      showToast({ type: AlertType.ERROR, message: DELETE_ERROR_MSG });
    }
  }, [personaId, deletePracticeProspect, dispatch, handleApiResponse, onClose, showToast]);

  const handleSave = useCallback(async () => {
    try {
      const response = await editPracticeProspect({
        id: personaId,
        managerNotes: notesInput,
        scorecardTemplateId: scorecardTemplateIdInput ?? null,
      });
      handleApiResponse({
        response,
        errorMsg: EDIT_ERROR_MSG,
        onSuccess: (newData) => {
          dispatch(updateProspect({ ...newData.prospect }));
          onClose();
        },
      });
    } catch (error) {
      console.error(error);
      showToast({ type: AlertType.ERROR, message: EDIT_ERROR_MSG });
    }
  }, [
    personaId,
    notesInput,
    scorecardTemplateIdInput,
    dispatch,
    editPracticeProspect,
    handleApiResponse,
    onClose,
    showToast,
  ]);

  return (
    <div className="flex">
      <TextButton
        text={isUsed ? 'Archive' : 'Delete'}
        color={ButtonColor.DESTRUCTIVE}
        variant={ButtonVariant.OUTLINE}
        onClick={isUsed ? () => setIsArchiveConfirmModalOpen(true) : () => setIsDeleteConfirmModalOpen(true)}
        loading={isArchiving || isDeleting}
      />
      <ButtonGroup disabled={isArchiving || isDeleting} loading={isSaving} className="ml-auto">
        <TextButton
          text="Save changes"
          variant={ButtonVariant.FILLED}
          color={ButtonColor.PRIMARY}
          onClick={handleSave}
        />
        <TextButton text="Cancel" onClick={onClose} />
      </ButtonGroup>
      <ConfirmModal
        destructive
        isOpen={isArchiveConfirmModalOpen}
        setIsOpen={setIsArchiveConfirmModalOpen}
        onConfirm={runArchive}
        title={`Archive ${fullName}`}
        confirmText={`Are you sure you want to archive this prospect? You will have the option to unarchive them later.`}
        buttonText="Archive"
        isLoading={isArchiving}
      />
      <ConfirmModal
        destructive
        isOpen={isDeleteConfirmModalOpen}
        setIsOpen={setIsDeleteConfirmModalOpen}
        onConfirm={runDelete}
        title={`Delete ${fullName}`}
        confirmText={`Are you sure you want to delete this prospect? This action cannot be undone.`}
        buttonText="Delete"
        isLoading={isDeleting}
      />
    </div>
  );
};

export default ManagerNotesActionButtons;
