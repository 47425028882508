import { useEffect } from 'react';
import { ButtonColor, Icon, TextButton } from '../../../../components';
import { MIN_REQUIRED_SCENARIO_ITEMS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { addPriority, deletePriority, randomizePriorities, updatePriority } from '../../../../redux/reducers';
import { ProspectPageMode } from '../../../../types';
import TabSection from '../TabSection';
import ScenarioListItem from './ScenarioListItem';

const Priorities = () => {
  const dispatch = useAppDispatch();
  const { mode, fields } = useAppSelector((state) => state.prospect);

  const priorities = fields.priorities.map((p) => p.value);
  const canDelete = priorities.length > MIN_REQUIRED_SCENARIO_ITEMS;

  const isViewMode = mode === ProspectPageMode.VIEW;

  // Delete empty priorities on unmount.
  useEffect(() => {
    return () => {
      if (priorities.length > MIN_REQUIRED_SCENARIO_ITEMS) {
        priorities.forEach((priority, index) => {
          if (!priority) {
            dispatch(deletePriority(index));
          }
        });
      }
    };
  }, []);

  return (
    <TabSection title="Priorities" onRandomize={() => dispatch(randomizePriorities())}>
      {priorities.map((priority, index) => (
        <ScenarioListItem
          key={index}
          index={index}
          canDelete={canDelete}
          isRequired={index < MIN_REQUIRED_SCENARIO_ITEMS}
          isViewMode={isViewMode}
          value={priority}
          deleteItem={() => dispatch(deletePriority(index))}
          updateItem={(value) => dispatch(updatePriority({ index, value }))}
        />
      ))}
      {!isViewMode && (
        <TextButton
          text="Add priority"
          startIcon={Icon.PLUS}
          color={ButtonColor.SECONDARY}
          onClick={() => {
            dispatch(addPriority());
          }}
        />
      )}
    </TabSection>
  );
};

export default Priorities;
