import { ColumnDef, Getter } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { ROLE_OPTIONS } from '../../../../../../constants';
import { useAppSelector, useFeatureFlag } from '../../../../../../hooks';
import { useGetInvitesQuery, useGetUsersQuery } from '../../../../../../services';
import { LD_FeatureFlags, Roles, TableUser, TeamData, TextColor } from '../../../../../../types';
import { conditionalArray, sortUsers } from '../../../../../../utils';
import {
  Badge,
  DataTable,
  findOptionByValue,
  MoreTagsLabel,
  Typography,
  TypographySize,
  UserBadge,
} from '../../../../../shared';
import ChangeRoleModal from './ChangeRoleModal';
import DisableConfirmModal from './DisableConfirmModal';
import EnableConfirmModal from './EnableConfirmModal';
import ManageTeamsModal from './ManageTeamsModal';
import RevokeConfirmModal from './RevokeConfirmModal';
import useUserActions, { UsersTableModal } from './useUserActions';

const MAX_TEAMS_NUM_DISPLAYED = 2;

const UsersTable = () => {
  const [openModal, setOpenModal] = useState<{ type: UsersTableModal; user: TableUser } | null>(null);

  const { data: users = [], isLoading: isOrgUsersLoading } = useGetUsersQuery({});
  const { data: invites = [], isLoading: isInvitesLoading } = useGetInvitesQuery();
  const isLoading = isOrgUsersLoading || isInvitesLoading;

  const { user: currUser, organization } = useAppSelector((state) => state.auth);
  const { isTeamsEnabled } = organization || {};
  const teamsFF = useFeatureFlag(LD_FeatureFlags.RELEASE_TEAMS);

  const { id: currUserId, role: currUserRole } = currUser || {};
  const isSalesRep = currUserRole === Roles.SALES_REP;

  // Transform invitees data for consistent structure with users.
  const invitees = invites.map(({ id, email, role }) => ({
    id,
    email,
    name: email,
    role,
    isInvitee: true,
    disabled: false,
  }));

  // Combine and sort users and invitees.
  const sortedUsers = [...users.map((user) => ({ ...user, isInvitee: false })), ...invitees].sort(sortUsers);

  const columns: ColumnDef<TableUser>[] = useMemo(
    () => [
      {
        accessorKey: 'user',
        header: 'User',
        size: 1 / 2,
        cell: ({ row }) => {
          const user = row.original;
          const { name, email, picture, id } = user;
          const isCurrUser = id === currUserId;
          return (
            <UserBadge
              title={`${name} ${isCurrUser ? '(You)' : ''}`}
              subtitle={name !== email ? email : undefined}
              imgSrc={picture}
            />
          );
        },
      },
      ...conditionalArray(!!isTeamsEnabled && teamsFF, {
        accessorKey: 'teamMembers',
        header: 'Team',
        cell: ({ getValue }: { getValue: Getter<TeamData[] | undefined> }) => {
          const teamMembers = getValue() || [];
          const visibleTeams = teamMembers.slice(0, MAX_TEAMS_NUM_DISPLAYED);
          const hiddenTeams = teamMembers.slice(MAX_TEAMS_NUM_DISPLAYED);

          return (
            <div className="flex flex-col gap-1">
              {visibleTeams.map(({ team }) => (
                <Badge key={team.id} label={team.name} color={team.color} maxWidth={100} />
              ))}
              {!!hiddenTeams.length && <MoreTagsLabel hiddenTags={hiddenTeams.map(({ team }) => team)} />}
            </div>
          );
        },
      }),
      {
        accessorKey: 'role',
        header: 'Role',
        cell: ({ row }) => {
          const user = row.original;
          const { role, disabled, isInvitee } = user;
          return (
            <div className="flex flex-col gap-1">
              <Typography>{findOptionByValue(ROLE_OPTIONS, role)?.label}</Typography>
              <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION} italic>
                {disabled ? 'Disabled' : isInvitee ? 'Invited' : ''}
              </Typography>
            </div>
          );
        },
      },
    ],
    [currUserId, isSalesRep, isTeamsEnabled, teamsFF]
  );

  const renderOpenModal = () => {
    if (!openModal) return null;

    const sharedProps = { user: openModal.user, closeModal: () => setOpenModal(null) };

    switch (openModal.type) {
      case UsersTableModal.DISABLE_CONFIRM:
        return <DisableConfirmModal {...sharedProps} />;
      case UsersTableModal.ENABLE_CONFIRM:
        return <EnableConfirmModal {...sharedProps} />;
      case UsersTableModal.REVOKE_CONFIRM:
        return <RevokeConfirmModal {...sharedProps} />;
      case UsersTableModal.CHANGE_ROLE:
        return <ChangeRoleModal {...sharedProps} />;
      case UsersTableModal.MANAGE_TEAMS:
        return <ManageTeamsModal {...sharedProps} />;
      default:
        return null;
    }
  };

  return (
    <div className="display-scrollbar-lg flex-1 overflow-y-auto">
      <DataTable
        columns={columns}
        data={sortedUsers}
        isLoading={isLoading}
        useEndActions={(rowUser) =>
          useUserActions({
            rowUser,
            openModal: (modal) => setOpenModal({ type: modal, user: rowUser }),
          })
        }
      />
      {renderOpenModal()}
    </div>
  );
};

export default UsersTable;
