export interface UpdateOrganizationSettingsPayload {
  fillerWordsPerMinute?: number;
  wordsPerMinute?: number;
  talkRatio?: number;
  longestMonologue?: number;
  vocabulary?: string[];
  repCanScoreCalls?: boolean;
  defaultScorecardTemplateId?: string;
}

export interface OrganizationSettings {
  fillerWordsPerMinute: number;
  wordsPerMinute: number;
  talkRatio: number;
  longestMonologue: number;
  vocabulary: string[];
  repCanScoreCalls: boolean;
  defaultScorecardTemplateId: string | null;
}

export interface OrganizationSettingsResponse {
  message: string;
  data: {
    settings: OrganizationSettings;
  };
}

export enum Benchmarks {
  FILLER_WORDS = 'fillerWordsPerMinute',
  TALK_SPEED = 'wordsPerMinute',
  TALK_RATIO = 'talkRatio',
  LONGEST_MONOLOGUE = 'longestMonologue',
}
