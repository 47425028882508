import { useState } from 'react';
import { AppRoutes, SIDEBAR_WIDTH } from '../../constants';
import { useAppSelector, useFilterOptionsAndSelections } from '../../hooks';
import { getCurrentAppRoute } from '../../utils';
import { CreateModal } from '../modals';
import { Roles } from '../../types';
import {
  ButtonColor,
  ButtonVariant,
  Divider,
  Icon,
  TextButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../shared';
import ActivityFilters from './ActivityFilters';
import AnalyticsFilters from './AnalyticsFilters';
import ReviewFilters from './ReviewFilters';
import SidebarFooterActions from './SidebarFooterActions';

const SidebarContent = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const currRoute = getCurrentAppRoute();
  const { accounts, prospects, tags } = useFilterOptionsAndSelections();

  const user = useAppSelector((state) => state.auth.user);
  const isSalesRep = user?.role === Roles.SALES_REP;

  const renderFilters = () => {
    switch (currRoute) {
      case AppRoutes.PRACTICE:
      case AppRoutes.QUIZ:
        return (
          <ActivityFilters
            accountOptionsAndSelections={accounts}
            prospectOptionsAndSelections={prospects}
            tagOptionsAndSelections={tags}
          />
        );
      case AppRoutes.REVIEW:
        return <ReviewFilters prospectOptionsAndSelections={prospects} tagOptionsAndSelections={tags} />;
      case AppRoutes.ANALYTICS:
        return <AnalyticsFilters tagOptionsAndSelections={tags} prospectOptionsAndSelections={prospects} />;
      default:
        return null;
    }
  };

  return (
    <div
      className="flex h-full w-full flex-col gap-4 overflow-hidden border-r border-base-100 p-4"
      style={{ width: SIDEBAR_WIDTH }}
    >
      <div className="flex flex-col gap-4">
        <TextButton
          text="Create"
          onClick={() => setIsCreateModalOpen(true)}
          fullWidth
          startIcon={isSalesRep ? Icon.LOCK : Icon.WAND}
          color={ButtonColor.AI}
          variant={ButtonVariant.FILLED}
          disabled={isSalesRep}
        />
        <Divider />
      </div>
      <Typography size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD} className="sticky top-0">
        Filters
      </Typography>
      <div className="flex flex-1 flex-col gap-6 overflow-y-auto pb-6">{renderFilters()}</div>
      <SidebarFooterActions />
      <CreateModal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} />
    </div>
  );
};

export default SidebarContent;
