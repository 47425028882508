import { useCallback } from 'react';
import { useHandleApiResponse, useToast, useUpdateOrgSeats } from '../../../../../../hooks';
import { useDisableUserMutation } from '../../../../../../services';
import { TableUser } from '../../../../../../types';
import { AlertType } from '../../../../../shared';
import ConfirmModal from '../../../../ConfirmModal';

const ERROR_MSG = 'Failed to disable user';

interface DisableConfirmModalProps {
  user: TableUser;
  closeModal: () => void;
}

const DisableConfirmModal = ({ user, closeModal }: DisableConfirmModalProps) => {
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  const updateOrgSeats = useUpdateOrgSeats();

  const [disableUser, { isLoading }] = useDisableUserMutation();

  // Handles disabling a user.
  const handleDisableUser = useCallback(async () => {
    try {
      const response = await disableUser({ id: user.id });

      const onSuccess = () => {
        closeModal();
        updateOrgSeats(user.role);
      };

      handleApiResponse({ response, errorMsg: ERROR_MSG, onSuccess });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [handleApiResponse, closeModal, showToast, updateOrgSeats]);

  return (
    <ConfirmModal
      isOpen
      buttonText="Disable"
      setIsOpen={closeModal}
      onConfirm={handleDisableUser}
      isLoading={isLoading}
      title="Confirm disable user"
      destructive
      confirmText={
        <>
          Are you sure you want to disable&nbsp;
          <span className="font-medium">{user.name}</span>?
        </>
      }
    />
  );
};

export default DisableConfirmModal;
