import { useMemo } from 'react';
import { useAppSelector, useFeatureFlag } from '../../../../../hooks';
import { LD_FeatureFlags, Permissions, TextColor } from '../../../../../types';
import { conditionalArray } from '../../../../../utils';
import { Accordion, AccordionType, Typography, TypographySize } from '../../../../shared';
import ItemWrapper from '../ItemWrapper';
import PromptTemplatesItem from './PromptTemplatesItem';
import SimulationDesignerItem from './SimulationDesignerItem';

interface CustomSimulationTabProps {
  onClose: () => void;
}

const CustomSimulationTab = ({ onClose }: CustomSimulationTabProps) => {
  const { organization, user } = useAppSelector((state) => state.auth);
  const isSelfServiceEnabled = !!organization?.isSelfServiceEnabled;
  const permissions = user?.permissions || [];
  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);
  const canManageProspect = permissions.includes(Permissions.MANAGE_PROSPECT);

  const selfServeFF = useFeatureFlag(LD_FeatureFlags.RELEASE_SELF_SERVE);

  const items = useMemo(
    () => [
      // Only admins and managers have access to the prospect designer.
      ...conditionalArray(canManageProspect, {
        id: 'simulation-designer',
        title: 'Simulation designer',
        content: <SimulationDesignerItem onClose={onClose} />,
        disabled: !selfServeFF || !isSelfServiceEnabled,
      }),
      // Only super-admins can manage prompt templates.
      ...conditionalArray(isSuperAdmin, {
        id: 'manage-prompt-templates',
        title: 'Manage prompt templates',
        content: <PromptTemplatesItem onClose={onClose} />,
      }),
    ],
    [canManageProspect, isSuperAdmin, selfServeFF, isSelfServiceEnabled]
  );

  return (
    <div className="flex flex-col gap-4">
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        Tailor a new simulation to your ICP and scenario.
      </Typography>
      <Accordion
        type={AccordionType.MULTIPLE}
        items={items.map((item) => ({ ...item, content: <ItemWrapper>{item.content}</ItemWrapper> }))}
      />
    </div>
  );
};

export default CustomSimulationTab;
