import { useCallback, useMemo, useState } from 'react';
import { ROLE_OPTIONS, SUPER_ADMIN_EMAIL_DOMAIN } from '../../../../../../constants';
import { useAppSelector, useHandleApiResponse, useToast, useUpdateOrgSeats } from '../../../../../../hooks';
import { useEditUserRoleMutation } from '../../../../../../services';
import { ComponentSize, Permissions, Roles, TableUser } from '../../../../../../types';
import { isHigherRole } from '../../../../../../utils';
import {
  AlertType,
  ButtonColor,
  ButtonGroup,
  Dialog,
  DialogType,
  Select,
  TextButton,
  findOptionByValue,
} from '../../../../../shared';

const ROLE_EDIT_ERROR_MSG = 'Failed to edit user role';

interface ChangeRoleModalProps {
  user: TableUser;
  closeModal: () => void;
}

const ChangeRoleModal = ({ user, closeModal }: ChangeRoleModalProps) => {
  const [selectedRole, setSelectedRole] = useState<Roles | undefined>(user?.role);

  // Mutations
  const [editUserRole, { isLoading: isEditLoading }] = useEditUserRoleMutation();
  const updateOrgSeats = useUpdateOrgSeats();

  // Hooks
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  // Redux
  const currUser = useAppSelector((state) => state.auth.user);

  const { id: rowUserId, role: rowUserRole, email: rowUserEmail } = user;
  const { role: currUserRole, permissions: currUserPermissions } = currUser || {};

  const isSuperAdmin = !!currUserPermissions?.includes(Permissions.ADMIN_ACCESS);
  const canManageSameRole = isSuperAdmin || currUserRole === Roles.ADMIN;

  const allowedRoleOptions = useMemo(() => {
    const canBeSuperAdmin = rowUserRole === Roles.SUPER_ADMIN || rowUserEmail?.endsWith(SUPER_ADMIN_EMAIL_DOMAIN);
    return ROLE_OPTIONS.filter(
      (option) =>
        currUserRole &&
        isHigherRole(currUserRole, option.value as Roles, canManageSameRole) &&
        (option.value !== Roles.SUPER_ADMIN || canBeSuperAdmin)
    );
  }, [currUserRole, rowUserRole, rowUserEmail, canManageSameRole]);

  const onSuccess = useCallback(() => {
    // Update organization seat counts
    updateOrgSeats(rowUserRole, selectedRole);
    closeModal();
  }, [updateOrgSeats, rowUserRole, selectedRole, closeModal]);

  const handleEditUserRole = useCallback(async () => {
    if (!selectedRole) return;
    try {
      const response = await editUserRole({ id: rowUserId, role: selectedRole });
      handleApiResponse({ response, errorMsg: ROLE_EDIT_ERROR_MSG, onSuccess });
    } catch (error) {
      console.error(`${ROLE_EDIT_ERROR_MSG}: `, error);
      showToast({ message: ROLE_EDIT_ERROR_MSG, type: AlertType.ERROR });
    }
  }, [rowUserId, handleApiResponse, showToast, editUserRole, onSuccess, selectedRole]);

  const selectedRoleOption = findOptionByValue(allowedRoleOptions, selectedRole);

  return (
    <Dialog isOpen onClose={closeModal} title="Change role" type={DialogType.CONFIRM}>
      <div className="flex flex-col gap-4">
        <Select
          options={allowedRoleOptions}
          onChange={(newRole) => setSelectedRole(newRole as Roles)}
          selected={selectedRoleOption}
        />
        <ButtonGroup loading={isEditLoading} size={ComponentSize.MEDIUM}>
          <TextButton
            text="Save changes"
            onClick={handleEditUserRole}
            disabled={selectedRole === rowUserRole}
            color={ButtonColor.PRIMARY}
          />
          <TextButton text="Cancel" onClick={closeModal} />
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export default ChangeRoleModal;
