import { PaginationState } from '@tanstack/react-table';
import { useCallback, useEffect, useState } from 'react';
import { AppRoutes, PAGINATION_PAGE_SIZE } from '../../constants';
import { useAppDispatch, useGetFiltersFromParams, useUpdateFilterParams } from '../../hooks';
import { setProspects } from '../../redux/reducers';
import { useGetPracticeProspectsMutation } from '../../services';
import {
  ActivityFilterKeys,
  ActivityFilters,
  CustomSortingState,
  ProspectSortingFilters,
  ProspectType,
  SortingOrder,
} from '../../types';
import { getCurrentAppRoute, parsePracticeFilters } from '../../utils';
import PracticePage from './PracticePage';
import ProspectTable from './ProspectTable';
import QuizPage from './QuizPage';

const ActivityPage = () => {
  const dispatch = useAppDispatch();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: PAGINATION_PAGE_SIZE,
  });

  const [sorting, setSorting] = useState<CustomSortingState>({
    sortBy: ProspectSortingFilters.UPDATED_AT,
    sortOrder: SortingOrder.DESC,
  });

  const [getPracticeProspects, { data, isLoading }] = useGetPracticeProspectsMutation();
  const totalPages = data?.pagination.totalPages;

  const filters: ActivityFilters = useGetFiltersFromParams();
  const updateFilterParams = useUpdateFilterParams();

  const currRoute = getCurrentAppRoute();
  const isQuiz = currRoute === AppRoutes.QUIZ;

  const fetchProspects = useCallback(
    (filtersToInclude: ActivityFilters, includeSavedView?: boolean) => {
      getPracticeProspects({
        ...parsePracticeFilters(filtersToInclude),
        pagination,
        sorting,
        types: [ProspectType.QUIZ],
        excludeTypes: !isQuiz, // Only show Quiz prospects in the Quiz page.
        includeSavedView,
      });
    },
    [isQuiz, pagination, sorting]
  );

  // Fetch prospects whenever filters change.
  useEffect(() => {
    fetchProspects(filters);
  }, [filters, fetchProspects]);

  // Fetch prospects with saved view when the page loads.
  // Only fetch saved view if there are no filters currently applied.
  useEffect(() => {
    const hasFilters = Object.keys(filters).length > 0;
    fetchProspects({}, !hasFilters);
  }, [fetchProspects]);

  // Reset to page 1 when filters change.
  useEffect(() => {
    if (pagination.pageIndex !== 1) {
      setPagination((prev) => ({ ...prev, pageIndex: 1 }));
    }
  }, [filters]);

  // This effect sets the list of prospects for activity pages.
  // It allows for optimistic updates to the state instead of refetching from the server.
  useEffect(() => {
    if (isLoading) return;
    dispatch(setProspects(data?.prospects || []));
    return () => {
      dispatch(setProspects([]));
    };
  }, [data?.prospects, isLoading, dispatch]);

  useEffect(() => {
    if (isLoading || !data?.savedView.filters || isQuiz) return;

    Object.entries(data.savedView.filters).forEach(([key, value]) => {
      const newValue = key === ActivityFilterKeys.DATE_CREATED ? value?.[0] : value;
      updateFilterParams(key as ActivityFilterKeys, newValue);
    });
  }, [isLoading, data?.savedView.filters, isQuiz]);

  const renderTable = () => (
    <ProspectTable
      isLoading={isLoading}
      isQuiz={isQuiz}
      pagination={pagination}
      sorting={sorting}
      totalPages={totalPages}
      setPagination={setPagination}
      setSorting={setSorting}
    />
  );

  if (isQuiz) {
    const filtersApplied = Object.keys(filters).length > 0;
    const isEmpty = !isLoading && !data?.prospects.length;
    return (
      <QuizPage filtersApplied={filtersApplied} isEmpty={isEmpty}>
        {renderTable()}
      </QuizPage>
    );
  }

  return <PracticePage>{renderTable()}</PracticePage>;
};

export default ActivityPage;
