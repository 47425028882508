import { snakeCaseToLabel } from './string.utils';

/**
 * Conditionally inserts key-value pairs into an array.
 */
export const conditionalArray = <T>(condition: boolean, ...elements: T[]): T[] => (condition ? elements : []);

/**
 * Conditionally inserts key-value pairs into an object.
 */
export const conditionalObject = <T>(condition: boolean, elements: T): Partial<T> => {
  return condition ? { ...elements } : {};
};

/** Generates a random element from an array. */
export const getRandomElement = <T>(array: T[]): T => {
  return array[Math.floor(Math.random() * array.length)];
};

/** Generates a random subset of an array. */
export const getRandomElements = <T>(array: T[], length: number) =>
  array.sort(() => Math.random() - 0.5).slice(0, length);

/** Checks if the value is a boolean. */
export const isBool = (value: unknown): value is boolean => typeof value === 'boolean';

/** Checks if the value is a number. */
export const isNum = (value: unknown): value is number => typeof value === 'number';

/** Checks if the value is a string. */
export const isStr = (value: unknown): value is string => typeof value === 'string';

/** Checks if the value is filled regardless of its type. */
export const isValueFilled = <T>(value: T) => {
  if (typeof value === 'string') {
    return !!value.trim();
  } else {
    return value !== undefined;
  }
};

/** Parses an array of values to an array of Select options. */
export const parseValuesToOptions = (array: string[]) =>
  array.map((value) => ({ label: snakeCaseToLabel(value), value }));
