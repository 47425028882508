import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { ComponentSize, TextColor } from '../../../../types';
import { Dot } from '../../Dot';
import { Checkbox } from '../../Checkbox';
import { Divider } from '../../Divider';
import { DropdownItem } from '../../DropdownItem';
import { Icons } from '../../Icons';
import { MULTI_SELECT_CHECKBOX_SIZE, SELECT_ICON_SIZE } from './BaseSelect.constants';
import { SelectOptionWithActive, SelectSize } from './BaseSelect.types';
import { TextInput } from '../../Input';
import { IconButton } from '../../Button';
import { Icon } from '../../Icons';
import { ButtonVariant, ButtonColor } from '../../Button';

interface BaseSelectOptionProps {
  index: number;
  option: SelectOptionWithActive;
  size: SelectSize;
  runOptionClick: (value: string) => void;
  hovered?: boolean;
  listRef?: React.MutableRefObject<HTMLDivElement[]>;
  multiSelect?: boolean;
}

const BaseSelectOption = ({
  index,
  option,
  size,
  runOptionClick,
  hovered,
  listRef,
  multiSelect,
}: BaseSelectOptionProps) => {
  const { active, color, destructive, divider, endElement, icon, label, onClick, onEdit, value } = option;

  const [editInput, setEditInput] = useState(label);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (onEdit) {
      setEditInput(label);
    }
  }, [label, onEdit]);

  const isCustomAction = !!onClick;
  // Only show divider if it's not the first option on the list.
  const showDivider = divider && index > 0;

  const assignRef = useCallback(
    (index: number) => (node: HTMLDivElement | null) => {
      if (node && listRef) {
        listRef.current[index] = node;
      }
    },
    [] // Initialize once on mount.
  );

  const handleEditConfirm = useCallback(() => {
    onEdit?.(editInput);
    setIsEditMode(false);
  }, [editInput, onEdit]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleEditConfirm();
      }
    },
    [handleEditConfirm]
  );

  const renderCheckbox = () => (
    <Checkbox size={MULTI_SELECT_CHECKBOX_SIZE} checked={!!active} setChecked={() => runOptionClick(value)} />
  );

  const renderStartElement = () => (
    <>
      {multiSelect && !isCustomAction && renderCheckbox()}
      {icon && <Icons icon={icon} size={SELECT_ICON_SIZE[size]} color={TextColor.SECONDARY} />}
      {color && <Dot color={color} size={ComponentSize.X_SMALL} />}
    </>
  );

  const renderEndElement = () => {
    // If the option has an onEdit function, render the edit and confirm buttons.
    if (onEdit) {
      return (
        <>
          {isEditMode && (
            <IconButton
              icon={Icon.CHECK}
              size={ComponentSize.X_SMALL}
              variant={ButtonVariant.GHOST}
              color={ButtonColor.SECONDARY}
              onClick={handleEditConfirm}
            />
          )}
          {!isEditMode && (
            <IconButton
              icon={Icon.EDIT}
              size={ComponentSize.X_SMALL}
              variant={ButtonVariant.GHOST}
              color={ButtonColor.SECONDARY}
              onClick={() => setIsEditMode(true)}
            />
          )}
        </>
      );
    }
    // If the option has an endElement, render it.
    return endElement;
  };

  const renderLabel = () => {
    if (isEditMode && !!onEdit) {
      return (
        <TextInput
          value={editInput}
          onChange={(e) => setEditInput(e.target.value)}
          size={ComponentSize.X_SMALL}
          onKeyDown={handleKeyDown}
        />
      );
    }
    return label;
  };

  return (
    <div key={value} className="flex flex-col gap-1">
      {showDivider && <Divider />}
      <DropdownItem
        label={renderLabel()}
        active={!multiSelect && !isCustomAction && active}
        startElement={renderStartElement()}
        endElement={renderEndElement()}
        onClick={() => {
          if (isCustomAction && onClick) {
            onClick();
          } else {
            runOptionClick(value);
          }
        }}
        destructive={destructive}
        hovered={hovered}
        ref={assignRef(index)}
      />
    </div>
  );
};

export default BaseSelectOption;
