import { useMemo } from 'react';
import { useAppSelector, useFeatureFlag } from '../../../../../../hooks';
import { LD_FeatureFlags, Permissions, Roles, TableUser } from '../../../../../../types';
import { conditionalArray, isHigherRole } from '../../../../../../utils';
import { Icon } from '../../../../../shared';

export enum UsersTableModal {
  DISABLE_CONFIRM = 'DISABLE_CONFIRM',
  ENABLE_CONFIRM = 'ENABLE_CONFIRM',
  REVOKE_CONFIRM = 'REVOKE_CONFIRM',
  CHANGE_ROLE = 'CHANGE_ROLE',
  MANAGE_TEAMS = 'MANAGE_TEAMS',
}

interface useUserActionsProps {
  rowUser: TableUser;
  openModal: (modal: UsersTableModal) => void;
}

const useUserActions = ({ rowUser, openModal }: useUserActionsProps) => {
  const {
    id: rowUserId,
    disabled: rowUserDisabled = false,
    isInvitee: rowUserIsInvitee = false,
    role: rowUserRole,
  } = rowUser;

  const { user: currUser, organization } = useAppSelector((state) => state.auth);
  const { id: currUserId, role: currUserRole, permissions: currUserPermissions } = currUser || {};
  const { isTeamsEnabled } = organization || {};

  // Check if the current user is the same as the row user.
  const isCurrUser = currUserId === rowUserId;

  // Permissions Check
  const isSuperAdmin = !!currUserPermissions?.includes(Permissions.ADMIN_ACCESS);
  const canRevokeInvite = !!currUserPermissions?.includes(Permissions.INVITE_USER);
  const canDisableUser = !!currUserPermissions?.includes(Permissions.DISABLE_USER);

  const teamsFF = useFeatureFlag(LD_FeatureFlags.RELEASE_TEAMS);

  // Check if the current user can edit the user's role.
  // Super admins can edit any role.
  // Admins can edit any role except super admins.
  // Managers can edit any role lower than their own.
  // Sales reps cannot edit any role.
  const canManageSameRole = isSuperAdmin || currUserRole === Roles.ADMIN;
  const canManageUser = !!currUserRole && isHigherRole(currUserRole, rowUserRole, canManageSameRole);

  // Check if the row user is disabled or an invitee.
  const isDisabledOrInvitee = rowUserDisabled || rowUserIsInvitee;

  // Show 'Change role' action if the current user can manage the row user's role and the row user is not disabled or an invitee.
  const showChangeRole = canManageUser && !isDisabledOrInvitee && !isCurrUser;

  // Show 'Revoke invitation' action if the current user can revoke invitations and the row user is an invitee.
  const showRevokeInvite = canRevokeInvite && rowUserIsInvitee;

  // Show 'Disable user' action if the current user can disable users and the row user is not disabled.
  const showDisableUser = canManageUser && canDisableUser && !isDisabledOrInvitee && !isCurrUser;

  // Show 'Enable user' action if the current user is a super admin and the row user is disabled.
  const showEnableUser = rowUserDisabled && isSuperAdmin;

  // Show 'Add or remove teams' action if org flag is enabled and the user is not disabled or an invitee.
  const canManageTeams = isCurrUser || canManageUser;
  const showManageTeams = canManageTeams && teamsFF && !isDisabledOrInvitee && !!isTeamsEnabled;

  const rowActions = useMemo(
    () => [
      ...conditionalArray(showManageTeams, {
        label: 'Add or remove teams',
        icon: Icon.USER,
        onClick: () => openModal(UsersTableModal.MANAGE_TEAMS),
      }),
      ...conditionalArray(showRevokeInvite, {
        label: 'Revoke invitation',
        icon: Icon.CLOSE,
        destructive: true,
        onClick: () => openModal(UsersTableModal.REVOKE_CONFIRM),
      }),
      ...conditionalArray(showEnableUser, {
        label: 'Enable user',
        icon: Icon.PLUS,
        onClick: () => openModal(UsersTableModal.ENABLE_CONFIRM),
      }),
      ...conditionalArray(showChangeRole, {
        label: 'Change role',
        icon: Icon.EDIT,
        onClick: () => openModal(UsersTableModal.CHANGE_ROLE),
      }),
      ...conditionalArray(showDisableUser, {
        label: 'Disable user',
        icon: Icon.CLOSE,
        destructive: true,
        onClick: () => openModal(UsersTableModal.DISABLE_CONFIRM),
      }),
    ],
    [openModal, showRevokeInvite, showEnableUser, showChangeRole, showDisableUser, showManageTeams]
  );

  return rowActions;
};

export default useUserActions;
