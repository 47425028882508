import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonColor, Divider, Select, Spinner, TextButton } from '../../../../components';
import { AppRoutes } from '../../../../constants';
import { useAppSelector } from '../../../../hooks';
import { useGetScorecardTemplateQuery, useGetScorecardTemplatesForSelectQuery } from '../../../../services';
import {
  BaseScorecardTemplate,
  ComponentSize,
  OrganizationStatus,
  Permissions,
  QuestionType,
  ScorecardTemplate,
} from '../../../../types';

interface NewCallScorecardProps {
  scorecardTemplate?: BaseScorecardTemplate | ScorecardTemplate;
  setScorecardTemplate: (scorecard: BaseScorecardTemplate | ScorecardTemplate | undefined) => void;
  setIsAiScoreable: (isAiScoreable: boolean) => void;
  handleClearAnswers: () => void;
}

const NewCallScorecard = ({
  scorecardTemplate,
  setScorecardTemplate,
  setIsAiScoreable,
  handleClearAnswers,
}: NewCallScorecardProps) => {
  const navigate = useNavigate();
  const { user, organization } = useAppSelector((state) => state.auth);

  const permissions = user?.permissions || [];
  const canModifyScorecardTemplates = permissions.includes(Permissions.MODIFY_SCORECARD_TEMPLATE);
  const isTrialOrg = organization?.status === OrganizationStatus.TRIAL;

  // Fetch all active scorecard templates
  const { data: scorecardTemplates, isLoading: isScorecardTemplatesLoading } = useGetScorecardTemplatesForSelectQuery(
    {}
  );

  // Fetch selected template details
  const { data: selectedTemplateDetails, isLoading: isSelectedTemplateLoading } = useGetScorecardTemplateQuery(
    scorecardTemplate?.id ?? '',
    {
      skip: !scorecardTemplate,
    }
  );

  // Update scorecardTemplate when selected template details are fetched
  useEffect(() => {
    if (selectedTemplateDetails) {
      setScorecardTemplate(selectedTemplateDetails);

      // Check if template has only YES_NO questions, if so then it's AI scoreable
      const isAiScoreable =
        selectedTemplateDetails?.sections?.every((section) =>
          section.questions.every((question) => question.type === QuestionType.YES_NO)
        ) ?? false;
      setIsAiScoreable(isAiScoreable);
    }
  }, [selectedTemplateDetails, setScorecardTemplate, setIsAiScoreable]);

  // Prepare options for the Select component
  const scorecardOptions = scorecardTemplates?.map((scorecard) => ({
    value: scorecard.id,
    label: scorecard.name,
  }));

  // Handle scorecard selection change
  const handleScorecardChange = (selectedValue?: string) => {
    // If the selected value is the same as the current scorecardTemplate's ID, return
    if (selectedValue === scorecardTemplate?.id) {
      return;
    }
    // Reset answers when selecting a new scorecard
    handleClearAnswers();

    // If no selected value, reset scorecardTemplate
    if (!selectedValue) {
      setScorecardTemplate(undefined);
      return;
    }

    const newSelectedScorecard = scorecardTemplates?.find((scorecard) => scorecard.id === selectedValue);
    setScorecardTemplate(newSelectedScorecard);
  };

  // Render "Create new" button
  const renderCreateNewScorecardButton = () => (
    <TextButton
      text="Create new"
      onClick={() => navigate(AppRoutes.SCORECARD_TEMPLATES)}
      color={ButtonColor.SECONDARY}
      disabled={isTrialOrg}
      fullWidth
    />
  );

  const renderSpinner = () => (
    <div className="flex h-full items-center justify-center">
      <Spinner size={ComponentSize.SMALL} />
    </div>
  );

  // Show "Create new" button if no scorecard is selected and user has permission to modify scorecard templates
  const showCreateNewScorecardButton = !scorecardTemplate && canModifyScorecardTemplates;

  // If scorecard templates are loading, show spinner
  if (isScorecardTemplatesLoading) return renderSpinner();

  // If there are no scorecard templates, only show the "Create new" button
  if (!scorecardTemplates?.length) return renderCreateNewScorecardButton();

  return (
    <>
      {/* Scorecard template selector */}
      <Select
        disabled={isSelectedTemplateLoading}
        options={scorecardOptions ?? []}
        placeholder="Select scorecard"
        selected={scorecardTemplate ? { value: scorecardTemplate.id, label: scorecardTemplate.name } : undefined}
        onChange={(selectedValue) => handleScorecardChange(selectedValue)}
      />
      {/* If selected template is loading, show spinner below the selector */}
      {isSelectedTemplateLoading && renderSpinner()}
      {showCreateNewScorecardButton && (
        <div className="flex flex-col gap-4">
          <Divider text="OR" />
          {renderCreateNewScorecardButton()}
        </div>
      )}
    </>
  );
};

export default NewCallScorecard;
