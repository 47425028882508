import { FilterOptionsAndSelections, useGetFiltersFromParams, useUpdateFilterParams } from '../../hooks';
import { ActivityFilterKeys, ActivityFilters as ActivityFiltersType } from '../../types';
import { SidebarDateFilter, SidebarSearchFilter, SidebarSelectFilter } from '../shared';

interface ActivityFiltersProps {
  accountOptionsAndSelections: FilterOptionsAndSelections;
  prospectOptionsAndSelections: FilterOptionsAndSelections;
  tagOptionsAndSelections: FilterOptionsAndSelections;
}

const ActivityFilters = ({
  accountOptionsAndSelections,
  prospectOptionsAndSelections,
  tagOptionsAndSelections,
}: ActivityFiltersProps) => {
  const filters: ActivityFiltersType = useGetFiltersFromParams();
  const updateFilterParams = useUpdateFilterParams();

  return (
    <>
      <SidebarSearchFilter
        onSearchChange={(newValue) => updateFilterParams(ActivityFilterKeys.SEARCH, newValue)}
        placeholder="Search keyword"
        value={filters.search}
      />
      <SidebarSelectFilter
        title="Prospect"
        placeholder="Select prospect"
        onChange={(newValue) => updateFilterParams(ActivityFilterKeys.PROSPECT, newValue)}
        {...prospectOptionsAndSelections}
      />
      <SidebarSelectFilter
        title="Prospect account"
        placeholder="Select prospect account"
        onChange={(newValue) => updateFilterParams(ActivityFilterKeys.PROSPECT_ACCOUNT, newValue)}
        {...accountOptionsAndSelections}
      />
      <SidebarSelectFilter
        title="Tags"
        placeholder="Select tag"
        onChange={(newValue) => updateFilterParams(ActivityFilterKeys.TAGS, newValue)}
        {...tagOptionsAndSelections}
      />
      <SidebarDateFilter
        title="Date created"
        onChange={(newValue) => updateFilterParams(ActivityFilterKeys.DATE_CREATED, newValue)}
        selected={filters.createdDate}
      />
    </>
  );
};

export default ActivityFilters;
