import { useState } from 'react';
import { useGetScorecardTemplatesForSelectQuery } from '../../../services';
import { PracticeProspect, TextColor } from '../../../types';
import { getAIScorecardTemplateOptions } from '../../../utils';
import { Dialog, findOptionByValue, Select, TextArea, Typography, TypographySize } from '../../shared';
import ManagerNotesActionButtons from './ManagerNotesActionButtons';

interface ManagerNotesModalProps {
  prospect: PracticeProspect;
  onClose: () => void;
}

const ManagerNotesModal = ({ prospect, onClose }: ManagerNotesModalProps) => {
  const { data: scorecardTemplates = [], isLoading: isLoadingScorecardTemplates } =
    useGetScorecardTemplatesForSelectQuery({ orgId: prospect.orgId });

  const [notesInput, setNotesInput] = useState(prospect.managerNotes || '');
  const [scorecardTemplateIdInput, setScorecardTemplateIdInput] = useState(prospect.scorecardTemplateId);

  const scorecardTemplateOptions = getAIScorecardTemplateOptions(scorecardTemplates);

  const selectedScorecardTemplateOption = findOptionByValue(scorecardTemplateOptions, scorecardTemplateIdInput);

  return (
    <Dialog isOpen onClose={onClose} title={`${prospect.firstName} ${prospect.lastName}`}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <Typography>AI scorecard</Typography>
            <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
              Automatically scores this call
            </Typography>
          </div>
          <Select
            loading={isLoadingScorecardTemplates}
            placeholder="Select scorecard"
            options={scorecardTemplateOptions}
            selected={selectedScorecardTemplateOption}
            onChange={(newValue) => setScorecardTemplateIdInput(newValue)}
            clearable
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <Typography>Manager notes</Typography>
            <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
              Only visible to managers and administrators
            </Typography>
          </div>
          <TextArea
            value={notesInput}
            rows={6}
            onChange={(e) => setNotesInput(e.target.value)}
            placeholder={`Example:\n\u0020\u0020• Use for Q2 onboarding class\n\u0020\u0020• Tests product knowledge\n\u0020\u0020• Tests competitor objections`}
          />
        </div>
        <ManagerNotesActionButtons
          notesInput={notesInput}
          prospect={prospect}
          onClose={onClose}
          scorecardTemplateIdInput={scorecardTemplateIdInput}
        />
      </div>
    </Dialog>
  );
};

export default ManagerNotesModal;
