import { createApi } from '@reduxjs/toolkit/query/react';
import { SKIP_AUTH_HEADER } from '../constants';
import {
  AddCommentPayload,
  ApiPracticeProspectDetails,
  ApiSuccess,
  ApiTagTypes,
  AppOrganization,
  AppUser,
  ApplyTagsPayload,
  ApplyTeamPayload,
  BasePracticeProspect,
  BaseScorecardTemplate,
  BaseTeamPayload,
  Call,
  CallResponse,
  CallsActivityData,
  CallsActivityPayload,
  CallsPayload,
  CallsResponse,
  Candidate,
  CandidatesResponse,
  Comment,
  CommentsResponseData,
  ConfirmUserPhoneNumberPayload,
  CreateCandidatePayload,
  CreatePracticeProspectPayload,
  CreatePromptTemplatePayload,
  CreateScorecardPayload,
  CreateScorecardTemplatePayload,
  CreateTagPayload,
  CreateTagResponse,
  EditPracticeProspectPayload,
  EditUserPhoneNumberPayload,
  EditUserRolePayload,
  FlagCallPayload,
  GenerateGongOAuthStateResponse,
  GenerateUploadUrlPayload,
  GenerateUploadUrlResponseData,
  GetPracticeProspectsForSelectParams,
  GongAPIKeyPayload,
  GongCallsData,
  GongCallsPayload,
  GongCallsResponse,
  GongCreateProspectPayload,
  GongUsersData,
  GongUsersResponse,
  HotlinePracticeProspectResponse,
  ImpersonateUserParams,
  ImpersonatedUserData,
  ImpersonatedUserResponse,
  InitiateCallPayload,
  InitiateCallResponseData,
  IntercomHmacResponseData,
  Invite,
  InviteUserPayload,
  InvitesResponse,
  OrgUsersResponse,
  OrganizationResponse,
  OrganizationSettings,
  OrganizationSettingsResponse,
  OrganizationsResponse,
  PagePagination,
  PhoneNumbersResponse,
  PracticeProspect,
  PracticeProspectDetails,
  PracticeProspectListResponse,
  PracticeProspectListResponseData,
  PracticeProspectsFilters,
  PracticeProspectsParams,
  PracticeProspectsSelectResponse,
  ProgressReportCallScoreData,
  ProgressReportCallScorePayload,
  ProgressReportMetricData,
  ProgressReportMetricPayload,
  PromptTemplate,
  PromptTemplatesParams,
  PromptTemplatesResponse,
  Scorecard,
  ScorecardResponse,
  ScorecardTemplate,
  ScorecardTemplateActionResponse,
  ScorecardTemplateResponse,
  ScorecardTemplatesForSelectParams,
  ScorecardTemplatesParams,
  ScorecardTemplatesResponse,
  ScorecardTemplatesSelectResponse,
  SendAccessRequestEmailPayload,
  SubmitAITwinLinkPayload,
  Tag,
  TagsResponse,
  Team,
  TeamData,
  TeamsData,
  UpdateOrganizationSettingsPayload,
  UpdatePromptTemplatePayload,
  UpdateSavedViewPayload,
  UpdateScorecardPayload,
  UpdateScorecardResponse,
  UpdateScorecardTemplatePayload,
  UpdatedPracticeProspectData,
  UsersForSelectParams,
  UsersForSelectResponse,
  UsersForSelectResponseData,
  UsersParams,
} from '../types';
import { getParsedAuth0User, getUrlWithPaginationAndSorting, parseProspect, transformToAppOrg } from '../utils';
import apiBaseQuery from './apiBaseQuery';

// Setup RTK Query API service with base configurations.
// Note: When adding new APIs, ensure that tags are provided.
export const api = createApi({
  reducerPath: 'api',
  baseQuery: apiBaseQuery,
  tagTypes: Object.values(ApiTagTypes),
  endpoints: (builder) => ({
    // -- CANDIDATES --
    createCandidate: builder.mutation<ApiSuccess, CreateCandidatePayload>({
      query: (candidateDate) => ({
        url: '/candidates/create',
        method: 'POST',
        body: candidateDate,
      }),
      invalidatesTags: [ApiTagTypes.CANDIDATES],
    }),
    deleteCandidate: builder.mutation<ApiSuccess, string>({
      query: (candidateId) => ({
        url: `/candidates/${candidateId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.CANDIDATES],
    }),
    getCandidates: builder.query<Candidate[], void>({
      query: () => '/candidates',
      transformResponse: (response: CandidatesResponse) => response.data.candidates,
      providesTags: [ApiTagTypes.CANDIDATES],
    }),

    // -- COMMENTS --
    addComment: builder.mutation<ApiSuccess, AddCommentPayload>({
      query: (commentData) => ({
        url: '/comments/create',
        method: 'POST',
        body: commentData,
      }),
      invalidatesTags: [ApiTagTypes.COMMENTS],
    }),
    deleteComment: builder.mutation<ApiSuccess, string>({
      query: (commentId) => ({
        url: `/comments/${commentId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.COMMENTS],
    }),
    getComments: builder.query<Comment[], string>({
      query: (callSid) => `/comments/${callSid}`,
      transformResponse: (response: CommentsResponseData) => response.data.comments,
      providesTags: [ApiTagTypes.COMMENTS],
    }),

    // -- CURRENT USER --
    getPhoneNumbers: builder.query<string[], void>({
      query: () => '/users/me/phone-numbers',
      transformResponse: (response: PhoneNumbersResponse) => response.data.phoneNumbers,
      providesTags: [ApiTagTypes.PHONE_NUMBERS],
    }),
    generateIntercomHMAC: builder.mutation<IntercomHmacResponseData, string>({
      query: (userId) => ({
        url: `/users/${userId}/generate-intercom-hmac`,
        method: 'POST',
      }),
      transformResponse: (response: ApiSuccess<IntercomHmacResponseData>) => response.data,
    }),

    // -- ORGANIZATIONS --
    getOrganization: builder.query<AppOrganization, string>({
      query: (orgId) => `/organizations/${orgId}`,
      transformResponse: (response: OrganizationResponse) => transformToAppOrg(response.data.organization),
    }),
    getOrganizations: builder.query<AppOrganization[], void>({
      query: () => ({
        url: '/organizations',
        params: {
          // Prevents user impersonation from being applied to this request.
          // This endpoint is only accessible to super admins and we don't allow super-admins to impersonate super-admins,
          // so we don't need to worry about impersonation here.
          disableImpersonation: true,
        },
      }),
      transformResponse: (response: OrganizationsResponse) => response.data.organizations.map(transformToAppOrg),
    }),

    // -- USERS --
    getUsers: builder.query<AppUser[], UsersParams>({
      query: (params) => ({
        url: '/users',
        params,
      }),
      transformResponse: (response: OrgUsersResponse) => response.data.users,
      providesTags: [ApiTagTypes.USERS],
    }),
    getImpersonatedUser: builder.mutation<ApiSuccess<ImpersonatedUserData>, ImpersonateUserParams>({
      query: (params) => ({
        url: '/users/impersonate',
        params: {
          ...params,
          // Prevents user impersonation from being applied to this request.
          // This endpoint is only accessible to super admins and we don't allow super-admins to impersonate super-admins,
          // so we don't need to worry about impersonation here.
          disableImpersonation: true,
        },
      }),
      transformResponse: (response: ImpersonatedUserResponse) => {
        return {
          ...response,
          data: {
            ...response.data.decodedToken,
            ...getParsedAuth0User({ ...response.data.decodedToken, roles: [response.data.decodedToken.role] }),
          },
        };
      },
    }),
    disableUser: builder.mutation<ApiSuccess, { id: string }>({
      query: ({ id }) => ({
        url: `/users/${id}/disable`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.USERS],
    }),
    initiateCall: builder.mutation<InitiateCallResponseData, InitiateCallPayload>({
      query: ({ to }) => ({
        url: '/twilio/initiate-call',
        method: 'POST',
        body: { to },
      }),
      transformResponse: (response: ApiSuccess<InitiateCallResponseData>) => response.data,
    }),
    confirmUserPhoneNumber: builder.mutation<ApiSuccess, ConfirmUserPhoneNumberPayload>({
      query: ({ id, phoneNumber }) => ({
        url: `/phone-verification/${id}/verify`,
        method: 'POST',
        body: { phoneNumber },
      }),
      invalidatesTags: [ApiTagTypes.PHONE_NUMBERS],
    }),
    deleteUserPhoneNumber: builder.mutation<ApiSuccess, EditUserPhoneNumberPayload>({
      query: ({ id, phoneNumber }) => ({
        url: `/users/${id}/phone-numbers/delete`,
        method: 'POST',
        body: { phoneNumber },
      }),
      invalidatesTags: [ApiTagTypes.PHONE_NUMBERS],
    }),
    editUserRole: builder.mutation<ApiSuccess, EditUserRolePayload>({
      query: ({ id, ...editData }) => ({
        url: `/users/${id}/role/edit`,
        method: 'POST',
        body: editData,
      }),
      invalidatesTags: [ApiTagTypes.USERS],
    }),
    enableUser: builder.mutation<ApiSuccess, { id: string }>({
      query: ({ id }) => ({
        url: `/users/${id}/enable`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.USERS],
    }),
    getUserTeams: builder.query<Team[], string>({
      query: (id) => `/users/${id}/teams`,
      transformResponse: (response: ApiSuccess<TeamsData>) => response.data.teams,
      providesTags: (_, __, id) => [{ type: ApiTagTypes.TEAMS, id }],
    }),
    applyTeams: builder.mutation<ApiSuccess, ApplyTeamPayload>({
      query: ({ id, teamIds }) => ({
        url: `/users/${id}/apply-teams`,
        method: 'POST',
        body: { teamIds },
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: ApiTagTypes.USERS, id },
        { type: ApiTagTypes.TEAMS, id },
        ApiTagTypes.USERS,
      ],
    }),

    // -- SETUP --
    onboardUser: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/users/${id}/onboard`,
        method: 'POST',
      }),
    }),
    finishSetup: builder.mutation<ApiSuccess, void>({
      query: () => ({
        url: '/users/me/finish-setup',
        method: 'POST',
        params: {
          // Prevents user impersonation from being applied to this request.
          // This endpoint is only used to check if the current user exists in our DB,
          // so we don't need to worry about impersonation here.
          disableImpersonation: true,
        },
      }),
    }),

    // -- CALLS --
    getCall: builder.query<Call, string>({
      query: (callSid) => ({
        url: `/calls/${callSid}`,
        method: 'GET',
      }),
      transformResponse: (response: CallResponse) => ({
        ...response.data.call,
        practiceProspect: parseProspect(response.data.call.practiceProspect),
      }),
      providesTags: (_, __, callSid) => [{ type: ApiTagTypes.CALLS, id: callSid }],
    }),
    getCalls: builder.mutation<{ calls: Call[]; pagination: PagePagination }, CallsPayload>({
      query: ({ pagination, sorting, ...filters }) => ({
        url: getUrlWithPaginationAndSorting('/calls', pagination, sorting),
        method: 'POST',
        body: filters,
      }),
      transformResponse: (response: CallsResponse) => ({
        calls: response.data.calls.map((call) => ({
          ...call,
          practiceProspect: parseProspect(call.practiceProspect),
        })),
        pagination: response.data.pagination,
      }),
    }),
    flagCall: builder.mutation<ApiSuccess, FlagCallPayload>({
      query: ({ callSid, flaggedUsers }) => ({
        url: `/calls/${callSid}/flag`,
        method: 'POST',
        body: { flaggedUsers },
      }),
      invalidatesTags: (_, __, { callSid }) => [{ type: ApiTagTypes.CALLS, id: callSid }],
    }),
    unflagCall: builder.mutation<ApiSuccess, string>({
      query: (callSid) => ({
        url: `/calls/${callSid}/flag`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, callSid) => [{ type: ApiTagTypes.CALLS, id: callSid }],
    }),

    // -- INVITES --
    getInvites: builder.query<Invite[], void>({
      query: () => '/invite',
      transformResponse: (response: InvitesResponse) => response.data.invitations,
      providesTags: [ApiTagTypes.INVITES],
    }),
    inviteUser: builder.mutation<ApiSuccess, InviteUserPayload>({
      query: ({ ...inviteData }) => ({
        url: '/invite',
        method: 'POST',
        body: inviteData,
      }),
      invalidatesTags: [ApiTagTypes.INVITES],
    }),
    revokeInvitation: builder.mutation<ApiSuccess, { id: string }>({
      query: ({ id }) => ({
        url: `/invite/${id}/revoke`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.INVITES],
    }),

    // -- PROSPECTS --
    getPracticeProspects: builder.mutation<
      PracticeProspectListResponseData,
      PracticeProspectsParams & PracticeProspectsFilters
    >({
      query: ({ includeSavedView, pagination, sorting, ...filters }) => ({
        url: '/prospects',
        method: 'POST',
        params: {
          includeSavedView,
          page: pagination?.pageIndex,
          perPage: pagination?.pageSize,
          ...sorting,
        },
        body: filters,
      }),
      transformResponse: (response: PracticeProspectListResponse) => ({
        ...response.data,
        prospects: response.data.prospects.map(parseProspect),
      }),
    }),
    getPracticeProspectById: builder.mutation<PracticeProspectDetails, string>({
      query: (prospectId) => `/prospects/${prospectId}`,
      transformResponse: (response: ApiSuccess<{ prospect: ApiPracticeProspectDetails }>) =>
        parseProspect(response.data.prospect),
    }),
    getHotlinePracticeProspect: builder.query<PracticeProspect, void>({
      query: () => '/prospects/hotline',
      transformResponse: (response: HotlinePracticeProspectResponse) => parseProspect(response.data.prospect),
      providesTags: [ApiTagTypes.HOTLINE_PROSPECT],
    }),
    applyTags: builder.mutation<ApiSuccess, ApplyTagsPayload>({
      query: ({ prospectId, ...tagData }) => ({
        url: `/prospects/${prospectId}/apply-tags`,
        method: 'POST',
        body: tagData,
      }),
      invalidatesTags: (_, __, { prospectId }) => [ApiTagTypes.CALLS, { type: ApiTagTypes.PROSPECTS, id: prospectId }],
    }),
    regeneratePrompt: builder.mutation<ApiSuccess<UpdatedPracticeProspectData>, string>({
      query: (prospectId) => ({
        url: `/prospects/${prospectId}/regenerate-prompt`,
        method: 'POST',
      }),
    }),
    createPracticeProspect: builder.mutation<ApiSuccess<UpdatedPracticeProspectData>, CreatePracticeProspectPayload>({
      query: ({ orgId, ...prospectData }) => ({
        url: '/prospects/create',
        method: 'POST',
        params: { orgId },
        body: prospectData,
      }),
    }),
    editPracticeProspect: builder.mutation<ApiSuccess<UpdatedPracticeProspectData>, EditPracticeProspectPayload>({
      query: ({ id, ...prospectUpdates }) => ({
        url: `/prospects/${id}/edit`,
        method: 'POST',
        body: prospectUpdates,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: ApiTagTypes.PROSPECTS, id }],
    }),
    archivePracticeProspect: builder.mutation<ApiSuccess<UpdatedPracticeProspectData>, string>({
      query: (id) => ({
        url: `/prospects/${id}/archive`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.PROSPECTS],
    }),
    deletePracticeProspect: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/prospects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagTypes.PROSPECTS],
    }),
    reactivatePracticeProspect: builder.mutation<ApiSuccess<UpdatedPracticeProspectData>, string>({
      query: (id) => ({
        url: `/prospects/${id}/reactivate`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.PROSPECTS],
    }),

    // -- SELECT --
    getPracticeProspectsForSelect: builder.query<BasePracticeProspect[], GetPracticeProspectsForSelectParams>({
      query: (params) => ({
        url: '/prospects/select',
        params,
      }),
      transformResponse: (response: PracticeProspectsSelectResponse) => response.data.prospects,
      providesTags: [ApiTagTypes.PROSPECTS_FOR_SELECT],
    }),
    getUsersForSelect: builder.query<UsersForSelectResponseData, UsersForSelectParams>({
      query: (params) => ({
        url: '/users/select',
        params,
      }),
      transformResponse: (response: UsersForSelectResponse) => {
        const users = response.data.users;
        const phoneNumbers = response.data.phoneNumbers;
        return { users, phoneNumbers };
      },
      providesTags: [ApiTagTypes.USERS_FOR_SELECT],
    }),

    // -- UPLOAD MODAL --
    generateUploadUrl: builder.mutation<GenerateUploadUrlResponseData, GenerateUploadUrlPayload>({
      query: ({ files }) => ({
        url: '/file/generate-upload-url',
        method: 'POST',
        body: { files },
      }),
      transformResponse: (response: ApiSuccess<GenerateUploadUrlResponseData>) => response.data,
    }),
    submitLink: builder.mutation<ApiSuccess, string>({
      query: (link) => ({
        url: '/url',
        method: 'POST',
        body: { url: link },
      }),
    }),
    submitAITwinLink: builder.mutation<ApiSuccess, SubmitAITwinLinkPayload>({
      query: (payload) => ({
        url: '/transcription-pipelines/process-url',
        method: 'POST',
        body: payload,
      }),
    }),
    uploadFile: builder.mutation<ApiSuccess, { file: File; uploadUrl: string; signal?: AbortSignal }>({
      query: ({ file, uploadUrl, signal }) => ({
        url: uploadUrl,
        method: 'PUT',
        body: file,
        skipAuth: true,
        signal,
        headers: {
          'Content-Type': file.type,
          // Skip Auth0 authentication for file uploads
          [SKIP_AUTH_HEADER]: 'true',
        },
      }),
    }),

    // -- TAGS --
    createTag: builder.mutation<CreateTagResponse, CreateTagPayload>({
      query: ({ ...tagData }) => ({
        url: `/tags/create`,
        method: 'POST',
        body: tagData,
      }),
      invalidatesTags: [ApiTagTypes.TAGS],
    }),
    getTags: builder.query<Tag[], void>({
      query: () => '/tags',
      transformResponse: (response: TagsResponse) => response.data.tags,
      providesTags: [ApiTagTypes.TAGS],
    }),

    // -- ACCESS REQUEST --
    sendAccessRequestEmail: builder.mutation<ApiSuccess, SendAccessRequestEmailPayload>({
      query: ({ email, role, icp }) => ({
        url: '/emails/send-access-request-email',
        method: 'POST',
        body: {
          email,
          role,
          icp,
        },
        headers: {
          [SKIP_AUTH_HEADER]: 'true',
        },
      }),
    }),

    // -- SCORECARD TEMPLATES --
    getScorecardTemplates: builder.query<ScorecardTemplate[], ScorecardTemplatesParams>({
      query: ({ sorting, status }) => ({
        url: '/scorecard-templates',
        params: { ...sorting, status },
      }),
      transformResponse: (response: ScorecardTemplatesResponse) => response.data.scorecardTemplates,
      providesTags: [ApiTagTypes.SCORECARD_TEMPLATES],
    }),
    getScorecardTemplatesForSelect: builder.query<BaseScorecardTemplate[], ScorecardTemplatesForSelectParams>({
      query: (params) => ({
        url: '/scorecard-templates/select',
        params,
      }),
      transformResponse: (response: ScorecardTemplatesSelectResponse) => response.data.scorecardTemplates,
      providesTags: [ApiTagTypes.SCORECARD_TEMPLATES_FOR_SELECT],
    }),
    createScorecardTemplate: builder.mutation<ScorecardTemplateActionResponse, CreateScorecardTemplatePayload>({
      query: (templateData) => ({
        url: '/scorecard-templates',
        method: 'POST',
        body: templateData,
      }),
      invalidatesTags: [ApiTagTypes.SCORECARD_TEMPLATES, ApiTagTypes.SCORECARD_TEMPLATES_FOR_SELECT],
    }),
    updateScorecardTemplate: builder.mutation<ScorecardTemplateActionResponse, UpdateScorecardTemplatePayload>({
      query: ({ id, ...templateData }) => ({
        url: `/scorecard-templates/${id}`,
        method: 'POST',
        body: templateData,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: ApiTagTypes.SCORECARD_TEMPLATES, id },
        ApiTagTypes.SCORECARD_TEMPLATES,
        ApiTagTypes.SCORECARD_TEMPLATES_FOR_SELECT,
      ],
    }),
    getScorecardTemplate: builder.query<ScorecardTemplate, string>({
      query: (id) => `/scorecard-templates/${id}`,
      transformResponse: (response: ScorecardTemplateResponse) => response.data.scorecardTemplate,
      providesTags: (_, __, id) => [{ type: ApiTagTypes.SCORECARD_TEMPLATES, id }],
    }),
    deleteScorecardTemplate: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/scorecard-templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagTypes.SCORECARD_TEMPLATES, ApiTagTypes.SCORECARD_TEMPLATES_FOR_SELECT],
    }),
    archiveScorecardTemplate: builder.mutation<ScorecardTemplateActionResponse, string>({
      query: (id) => ({
        url: `/scorecard-templates/${id}/archive`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, id) => [
        { type: ApiTagTypes.SCORECARD_TEMPLATES, id },
        ApiTagTypes.SCORECARD_TEMPLATES,
        ApiTagTypes.SCORECARD_TEMPLATES_FOR_SELECT,
      ],
    }),
    duplicateScorecardTemplate: builder.mutation<ScorecardTemplateActionResponse, string>({
      query: (id) => ({
        url: `/scorecard-templates/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.SCORECARD_TEMPLATES, ApiTagTypes.SCORECARD_TEMPLATES_FOR_SELECT],
    }),

    // -- SCORECARD --
    getCallScorecard: builder.query<Scorecard, string>({
      query: (callSid) => `/calls/${callSid}/scorecard`,
      transformResponse: (response: ScorecardResponse) => response.data.scorecard,
      providesTags: (_, __, callSid) => [{ type: ApiTagTypes.SCORECARDS, id: callSid }],
    }),
    createScorecard: builder.mutation<ApiSuccess<UpdateScorecardResponse>, CreateScorecardPayload>({
      query: (scorecardData) => ({
        url: '/scorecards',
        method: 'POST',
        body: scorecardData,
      }),
      invalidatesTags: [ApiTagTypes.SCORECARDS, ApiTagTypes.SCORECARD_TEMPLATES],
    }),
    updateScorecard: builder.mutation<ApiSuccess<UpdateScorecardResponse>, UpdateScorecardPayload>({
      query: ({ id, ...scorecardData }) => ({
        url: `/scorecards/${id}`,
        method: 'POST',
        body: scorecardData,
      }),
      invalidatesTags: (_, __, { callSid }) => [
        { type: ApiTagTypes.SCORECARDS, id: callSid },
        ApiTagTypes.SCORECARD_TEMPLATES,
      ],
    }),
    deleteScorecard: builder.mutation<ApiSuccess<UpdateScorecardResponse>, { id: string; callSid: string }>({
      query: ({ id }) => ({
        url: `/scorecards/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { callSid }) => [
        { type: ApiTagTypes.SCORECARDS, id: callSid },
        ApiTagTypes.SCORECARD_TEMPLATES,
      ],
    }),

    // -- GONG AUTH --
    generateGongOauthState: builder.mutation<string, void>({
      query: () => ({
        url: '/gong/oauth-state',
        method: 'POST',
      }),
      transformResponse: (response: GenerateGongOAuthStateResponse) => response.data.state,
    }),
    submitGongAPIKey: builder.mutation<ApiSuccess, GongAPIKeyPayload>({
      query: (apiKeyData) => ({
        url: '/gong/api-key',
        method: 'POST',
        body: apiKeyData,
      }),
    }),
    disconnectGong: builder.mutation<ApiSuccess, void>({
      query: () => ({
        url: '/gong/disconnect',
        method: 'DELETE',
      }),
    }),

    // -- GONG --
    getGongUsers: builder.query<GongUsersData, void>({
      query: () => '/gong/users',
      transformResponse: (response: GongUsersResponse) => response.data,
      providesTags: [ApiTagTypes.GONG_USERS],
    }),
    getGongCalls: builder.mutation<GongCallsData, GongCallsPayload>({
      query: ({ cursor, ...filters }) => ({
        url: '/gong/calls',
        method: 'POST',
        body: filters,
        params: { cursor },
      }),
      transformResponse: (response: GongCallsResponse) => response.data,
    }),
    createGongProspect: builder.mutation<ApiSuccess, GongCreateProspectPayload>({
      query: (payload) => ({
        url: '/gong/calls/create-prospects',
        method: 'POST',
        body: payload,
      }),
    }),

    // -- ANALYTICS --
    getCallsActivityAnalytics: builder.mutation<CallsActivityData, CallsActivityPayload>({
      transformResponse: (response: ApiSuccess<CallsActivityData>) => response.data,
      query: (filters) => ({
        url: '/analytics/calls/activity',
        method: 'POST',
        body: filters,
      }),
    }),

    getCallScoreAnalytics: builder.mutation<ProgressReportCallScoreData, ProgressReportCallScorePayload>({
      transformResponse: (response: ApiSuccess<{ analytics: ProgressReportCallScoreData }>) => response.data.analytics,
      query: (filters) => ({
        url: '/analytics/calls/progress-report/call-score',
        method: 'POST',
        body: filters,
      }),
    }),

    getCallMetricAnalytics: builder.mutation<ProgressReportMetricData, ProgressReportMetricPayload>({
      transformResponse: (response: ApiSuccess<{ analytics: ProgressReportMetricData }>) => response.data.analytics,
      query: ({ metric, ...filters }) => ({
        url: `/analytics/calls/progress-report/${metric}`,
        method: 'POST',
        body: filters,
      }),
    }),

    // -- ORGANIZATION SETTINGS --
    getOrganizationSettings: builder.query<OrganizationSettings, void>({
      query: () => '/organization-settings',
      transformResponse: (response: OrganizationSettingsResponse) => response.data.settings,
      providesTags: [ApiTagTypes.ORGANIZATION_SETTINGS],
    }),

    updateOrganizationSettings: builder.mutation<ApiSuccess, UpdateOrganizationSettingsPayload>({
      query: (settings) => ({
        url: '/organization-settings',
        method: 'POST',
        body: settings,
      }),
      invalidatesTags: [ApiTagTypes.ORGANIZATION_SETTINGS],
    }),

    // -- PROMPT TEMPLATES --
    getPromptTemplates: builder.query<PromptTemplate[], PromptTemplatesParams>({
      query: (params) => ({
        url: '/prompt-templates',
        params,
      }),
      transformResponse: (response: PromptTemplatesResponse) => response.data.promptTemplates,
      providesTags: [ApiTagTypes.PROMPT_TEMPLATES],
    }),
    archivePromptTemplate: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/prompt-templates/${id}/archive`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.PROMPT_TEMPLATES],
    }),
    createPromptTemplate: builder.mutation<ApiSuccess, CreatePromptTemplatePayload>({
      query: (payload) => ({
        url: '/prompt-templates',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [ApiTagTypes.PROMPT_TEMPLATES],
    }),
    updatePromptTemplate: builder.mutation<ApiSuccess, UpdatePromptTemplatePayload>({
      query: ({ id, ...payload }) => ({
        url: `/prompt-templates/${id}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [ApiTagTypes.PROMPT_TEMPLATES],
    }),
    duplicatePromptTemplate: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/prompt-templates/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.PROMPT_TEMPLATES],
    }),
    deletePromptTemplate: builder.mutation<ApiSuccess, string>({
      query: (id) => ({
        url: `/prompt-templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ApiTagTypes.PROMPT_TEMPLATES],
    }),

    // -- SAVED VIEWS --
    updateSavedView: builder.mutation<ApiSuccess, UpdateSavedViewPayload>({
      query: (payload) => ({
        url: '/saved-views',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [ApiTagTypes.SAVED_VIEWS],
    }),

    // -- TEAMS --
    getTeams: builder.query<Team[], void>({
      query: () => '/teams',
      transformResponse: (response: ApiSuccess<TeamsData>) => response.data.teams,
      providesTags: [ApiTagTypes.TEAMS],
    }),

    createTeam: builder.mutation<ApiSuccess<TeamData>, BaseTeamPayload>({
      query: (payload) => ({
        url: '/teams/create',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [ApiTagTypes.TEAMS],
    }),

    editTeam: builder.mutation<ApiSuccess<TeamData>, Team>({
      query: ({ id, ...payload }) => ({
        url: `/teams/${id}/edit`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: ApiTagTypes.TEAMS, id }, ApiTagTypes.TEAMS, ApiTagTypes.USERS],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useCreateCandidateMutation,
  useDeleteCandidateMutation,
  useGetCandidatesQuery,
  useAddCommentMutation,
  useDeleteCommentMutation,
  useGetCommentsQuery,
  useDisableUserMutation,
  useInitiateCallMutation,
  useConfirmUserPhoneNumberMutation,
  useDeleteUserPhoneNumberMutation,
  useEditUserRoleMutation,
  useEnableUserMutation,
  useGetCallQuery,
  useGetCallsMutation,
  useFlagCallMutation,
  useUnflagCallMutation,
  useGetInvitesQuery,
  useGetPhoneNumbersQuery,
  useGenerateIntercomHMACMutation,
  useGetPracticeProspectsMutation,
  useGetPracticeProspectsForSelectQuery,
  useGetHotlinePracticeProspectQuery,
  useGetPracticeProspectByIdMutation,
  useGetUsersQuery,
  useGetUsersForSelectQuery,
  useGetImpersonatedUserMutation,
  useInviteUserMutation,
  useOnboardUserMutation,
  useFinishSetupMutation,
  useRevokeInvitationMutation,
  useGenerateUploadUrlMutation,
  useSubmitLinkMutation,
  useSubmitAITwinLinkMutation,
  useUploadFileMutation,
  useGetOrganizationQuery,
  useGetOrganizationsQuery,
  useGetTagsQuery,
  useApplyTagsMutation,
  useArchivePracticeProspectMutation,
  useCreatePracticeProspectMutation,
  useDeletePracticeProspectMutation,
  useEditPracticeProspectMutation,
  useReactivatePracticeProspectMutation,
  useRegeneratePromptMutation,
  useCreateTagMutation,
  useSendAccessRequestEmailMutation,
  useGetScorecardTemplatesQuery,
  useGetScorecardTemplatesForSelectQuery,
  useCreateScorecardTemplateMutation,
  useGetScorecardTemplateQuery,
  useUpdateScorecardTemplateMutation,
  useDeleteScorecardTemplateMutation,
  useArchiveScorecardTemplateMutation,
  useDuplicateScorecardTemplateMutation,
  useGetCallScorecardQuery,
  useCreateScorecardMutation,
  useUpdateScorecardMutation,
  useDeleteScorecardMutation,
  useGenerateGongOauthStateMutation,
  useSubmitGongAPIKeyMutation,
  useGetCallsActivityAnalyticsMutation,
  useGetCallScoreAnalyticsMutation,
  useGetCallMetricAnalyticsMutation,
  useCreateGongProspectMutation,
  useGetGongUsersQuery,
  useGetGongCallsMutation,
  useDisconnectGongMutation,
  useGetOrganizationSettingsQuery,
  useUpdateOrganizationSettingsMutation,
  useGetPromptTemplatesQuery,
  useArchivePromptTemplateMutation,
  useCreatePromptTemplateMutation,
  useUpdatePromptTemplateMutation,
  useDuplicatePromptTemplateMutation,
  useDeletePromptTemplateMutation,
  useUpdateSavedViewMutation,
  useGetTeamsQuery,
  useApplyTeamsMutation,
  useCreateTeamMutation,
  useEditTeamMutation,
  useGetUserTeamsQuery,
} = api;
