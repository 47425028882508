import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { AlertType } from '../components';
import { useApplyTagsMutation, useEditPracticeProspectMutation } from '../services';
import { Tag } from '../types';
import useHandleApiResponse from './useHandleApiResponse';
import useToast from './useToast';

const NOTES_UPDATE_ERROR_MSG = 'Failed to update prospect notes';
const NOTES_UPDATE_SUCCESS_MSG = 'Prospect notes updated successfully';

const TAGS_UPDATE_ERROR_MSG = 'Failed to update prospect tags';
const TAGS_UPDATE_SUCCESS_MSG = 'Prospect tags updated successfully';

interface UseUpdateProspectTagsAndNotesProps {
  notesInput: string;
  selectedTags: Tag[];
  notes: string;
  tags: Tag[];
  onApplyNotes: () => void;
  onApplyTags: () => void;
  setNotesInput: Dispatch<SetStateAction<string>>;
  setSelectedTags: Dispatch<SetStateAction<Tag[]>>;
  personaId?: string;
}

const useUpdateProspectTagsAndNotes = ({
  notesInput,
  selectedTags,
  notes,
  tags,
  onApplyNotes,
  onApplyTags,
  setNotesInput,
  setSelectedTags,
  personaId,
}: UseUpdateProspectTagsAndNotesProps) => {
  const [applyTags, { isLoading: isApplyingTags }] = useApplyTagsMutation();
  const [editPracticeProspect, { isLoading: isEditingNotes }] = useEditPracticeProspectMutation();

  const handleApiResponse = useHandleApiResponse(true);
  const { showToast } = useToast();

  const tagIdsToApply = useMemo(
    () => selectedTags.filter((newTag) => !tags.find((oldTag) => oldTag.id === newTag.id)).map((tag) => tag.id),
    [selectedTags, tags]
  );

  const tagIdsToRemove = useMemo(
    () => tags.filter((oldTag) => !selectedTags.find((newTag) => newTag.id === oldTag.id)).map((tag) => tag.id),
    [tags, selectedTags]
  );

  const onNotesError = useCallback(() => {
    setNotesInput(notes);
    showToast({ type: AlertType.ERROR, message: NOTES_UPDATE_ERROR_MSG });
  }, [setNotesInput, notes, showToast]);

  const runApplyNotes = useCallback(
    async (id: string) => {
      if (notes === notesInput) return;
      try {
        const response = await editPracticeProspect({ id, notes: notesInput });
        handleApiResponse({
          response,
          errorMsg: NOTES_UPDATE_ERROR_MSG,
          onError: onNotesError,
          onSuccess: () => {
            onApplyNotes();
            showToast({ type: AlertType.SUCCESS, message: NOTES_UPDATE_SUCCESS_MSG });
          },
        });
      } catch (error) {
        console.error(NOTES_UPDATE_ERROR_MSG, error);
        onNotesError();
      }
    },
    [notes, notesInput, editPracticeProspect, handleApiResponse, onApplyNotes, onNotesError, showToast]
  );

  const onTagsError = useCallback(() => {
    setSelectedTags(tags);
    showToast({ type: AlertType.ERROR, message: TAGS_UPDATE_ERROR_MSG });
  }, [setSelectedTags, tags, showToast]);

  const runApplyTags = useCallback(
    async (id: string) => {
      // If there are no tags to apply or remove, return.
      if (!tagIdsToApply.length && !tagIdsToRemove.length) return;

      // Otherwise, we need to update the prospect tags.
      try {
        const response = await applyTags({
          prospectId: id,
          tagsToApply: tagIdsToApply,
          tagsToRemove: tagIdsToRemove,
        });
        handleApiResponse({
          response,
          errorMsg: TAGS_UPDATE_ERROR_MSG,
          onError: onTagsError,
          onSuccess: () => {
            onApplyTags();
            showToast({ type: AlertType.SUCCESS, message: TAGS_UPDATE_SUCCESS_MSG });
          },
        });
      } catch (error) {
        console.error(TAGS_UPDATE_ERROR_MSG, error);
        onTagsError();
      }
    },
    [tagIdsToApply, tagIdsToRemove, tags, applyTags, handleApiResponse, onApplyTags, onTagsError, showToast]
  );

  const runApplyTagsAndNotes = useCallback(async () => {
    // If there is no personaId, we are not updating an existing prospect,
    // so we can immediately call the onSuccess callback.
    if (!personaId) {
      onApplyTags();
      onApplyNotes();
      return;
    }

    // We handle updating tags and notes separately because they are two different API calls.
    await runApplyTags(personaId);
    await runApplyNotes(personaId);
  }, [personaId, runApplyTags, runApplyNotes, onApplyTags, onApplyNotes]);

  return { runApplyTagsAndNotes, isLoading: isApplyingTags || isEditingNotes };
};

export default useUpdateProspectTagsAndNotes;
