import { Icon, Icons, Typography } from '../../../components';
import { useGetFiltersFromParams } from '../../../hooks';
import { AnalyticsFilters, ComponentSize } from '../../../types';
import CallScoreChart from './CallScoreChart';
import CallMetricChart from './CallMetricChart';

const ProgressReportTab = () => {
  // Fetch filters from params
  const filters = useGetFiltersFromParams() as AnalyticsFilters;

  // If no user is selected, show the select user  error state
  if (!filters.userId) {
    return (
      <div className="mt-36 flex flex-col gap-2 p-4">
        <Icons icon={Icon.ARROW_LEFT} size={ComponentSize.LARGE} />
        <Typography>Select a user to see insights</Typography>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      <CallScoreChart filters={filters} />
      <CallMetricChart filters={filters} />
    </div>
  );
};

export default ProgressReportTab;
