import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setProspectForm } from '../../../redux/reducers';
import { useGetPracticeProspectByIdMutation } from '../../../services';
import { ComponentSize, ProspectPageTabs, ProspectType } from '../../../types';
import {
  ButtonColor,
  ButtonGroup,
  DesignerModalCard,
  OrgSelector,
  TextButton,
  Typography,
  TypographySize,
} from '../../shared';
import SimulationPurposeSelector from './SimulationPurposeSelector';
import SimulationTemplateSelector from './SimulationTemplateSelector';

export enum SimulationDesignerOption {
  TEMPLATE = 'TEMPLATE',
  CUSTOM = 'CUSTOM',
}

interface SimulationTypeStepProps {
  isSuperAdmin: boolean;
  selectedDesignerOption: SimulationDesignerOption;
  goToNextStep: () => void;
  onCancel: () => void;
  setTargetOrgId: Dispatch<SetStateAction<string | undefined>>;
  setSelectedDesignerOption: Dispatch<SetStateAction<SimulationDesignerOption>>;
  setNewProspectPurpose: Dispatch<SetStateAction<ProspectType | undefined>>;
  setVisitedTabs: Dispatch<SetStateAction<Set<ProspectPageTabs>>>;
  newProspectPurpose?: ProspectType;
  targetOrgId?: string;
}

const SimulationTypeStep = ({
  isSuperAdmin,
  selectedDesignerOption,
  goToNextStep,
  onCancel,
  setNewProspectPurpose,
  setTargetOrgId,
  setSelectedDesignerOption,
  setVisitedTabs,
  targetOrgId,
  newProspectPurpose,
}: SimulationTypeStepProps) => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state) => state.auth.organization);
  const isHiringModuleEnabled = organization?.isHiringModuleEnabled || false;

  const [templateOrgId, setTemplateOrgId] = useState<string>();
  const [templateProspectId, setTemplateProspectId] = useState<string>();

  const [getPracticeProspectById, { isLoading: isProspectLoading }] = useGetPracticeProspectByIdMutation();

  // Disable the Next button if the user hasn't selected the required fields.
  const disabled =
    !targetOrgId ||
    (selectedDesignerOption === SimulationDesignerOption.CUSTOM && !newProspectPurpose) ||
    (selectedDesignerOption === SimulationDesignerOption.TEMPLATE && !templateProspectId);

  // Filters prospect types based on user/org permissions:
  const filterProspectTypes = useCallback(
    (type: ProspectType) => {
      // Super-admins can see all public types.
      if (isSuperAdmin) return true;
      // Only orgs with the hiring module enabled can see Candidates prospects.
      if (type === ProspectType.CANDIDATES) return isHiringModuleEnabled;
      // Non-super-admins cannot see Quiz prospects.
      if (type === ProspectType.QUIZ) return false;
      // All other prospect types are public.
      return true;
    },
    [isSuperAdmin, isHiringModuleEnabled]
  );

  const options = useMemo(
    () => [
      {
        id: SimulationDesignerOption.TEMPLATE,
        isSelected: selectedDesignerOption === SimulationDesignerOption.TEMPLATE,
        title: 'Based on existing simulation',
        description: 'Which simulation do you want to duplicate?',
        children: (
          <SimulationTemplateSelector
            isSuperAdmin={isSuperAdmin}
            templateOrgId={templateOrgId}
            templateProspectId={templateProspectId}
            filterProspectTypes={filterProspectTypes}
            setTemplateOrgId={setTemplateOrgId}
            setTemplateProspectId={setTemplateProspectId}
            onChange={() => setSelectedDesignerOption(SimulationDesignerOption.TEMPLATE)}
          />
        ),
        onSelect: () => setSelectedDesignerOption(SimulationDesignerOption.TEMPLATE),
      },
      {
        id: SimulationDesignerOption.CUSTOM,
        isSelected: selectedDesignerOption === SimulationDesignerOption.CUSTOM,
        title: 'Create new simulation',
        description: 'How will this simulation be used?',
        children: (
          <SimulationPurposeSelector
            newProspectPurpose={newProspectPurpose}
            filterProspectTypes={filterProspectTypes}
            onChange={() => setSelectedDesignerOption(SimulationDesignerOption.CUSTOM)}
            setNewProspectPurpose={setNewProspectPurpose}
          />
        ),
        onSelect: () => setSelectedDesignerOption(SimulationDesignerOption.CUSTOM),
      },
    ],
    [isSuperAdmin, templateOrgId, templateProspectId, newProspectPurpose, selectedDesignerOption, filterProspectTypes]
  );

  // Sets the org id and prospect type in the redux store.
  // This automatically closes the designer modal since it only opens if orgId or prospectType are not set.
  const handleNext = useCallback(async () => {
    if (selectedDesignerOption === SimulationDesignerOption.CUSTOM) {
      goToNextStep();
    } else if (templateProspectId) {
      // If the user has selected a template prospect, fetch the prospect data and set the form state.
      const { data: prospect } = await getPracticeProspectById(templateProspectId);
      if (prospect && !!targetOrgId) {
        dispatch(setProspectForm({ prospect: { ...prospect, orgId: targetOrgId }, isTemplate: true }));
        // Mark all tabs as visited except for the "Prompt" tab since we are creating a new prospect, but with preset data.
        setVisitedTabs(new Set(Object.values(ProspectPageTabs).slice(0, -1)));
      }
    }
  }, [
    dispatch,
    targetOrgId,
    selectedDesignerOption,
    templateProspectId,
    getPracticeProspectById,
    goToNextStep,
    setVisitedTabs,
  ]);

  return (
    <div className="flex flex-col gap-4">
      {options.map((option) => (
        <DesignerModalCard key={option.id} {...option} />
      ))}
      {isSuperAdmin && (
        <div className="flex flex-col gap-4">
          <Typography size={TypographySize.H5}>What organization is this simulation for?</Typography>
          <OrgSelector selectFirstAsDefault selectedOrgId={targetOrgId} onChange={setTargetOrgId} />
        </div>
      )}
      <ButtonGroup size={ComponentSize.MEDIUM} loading={isProspectLoading}>
        <TextButton onClick={handleNext} text="Next" color={ButtonColor.PRIMARY} disabled={disabled} />
        <TextButton onClick={onCancel} text="Cancel" />
      </ButtonGroup>
    </div>
  );
};

export default SimulationTypeStep;
