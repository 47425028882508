import clsx from 'clsx';
import { useState } from 'react';
import { Badge, Icon, Icons } from '../..';
import { ComponentSize, TextColor } from '../../../types';

interface ManageTagsAndNotesTriggerProps {
  canManageNotes: boolean;
  showBadge: boolean;
  active?: boolean;
  onClick?: () => void;
}

const ManageTagsAndNotesTrigger = ({ active, canManageNotes, showBadge, onClick }: ManageTagsAndNotesTriggerProps) => {
  const [hover, setHover] = useState(false);

  const bgColor = active || hover ? 'bg-neutral' : 'bg-gray-100';
  const iconColor = active || hover ? TextColor.WHITE : TextColor.PRIMARY;

  if (showBadge) {
    return (
      <Badge
        active={active}
        label={`Add tags${canManageNotes ? ' and notes' : ''}`}
        outline
        startIcon={Icon.PLUS}
        onClick={onClick}
      />
    );
  }

  return (
    <div
      className={clsx('flex h-fit w-fit cursor-pointer items-center justify-center rounded-full p-[1px]', bgColor)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
    >
      <Icons icon={Icon.PLUS} color={iconColor} size={ComponentSize.X_SMALL} />
    </div>
  );
};

export default ManageTagsAndNotesTrigger;
