import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tabs, TabsType } from '../../components';
import { ActivityTab } from './ActivityTab';
import { useFeatureFlag } from '../../hooks';
import { AnalyticsTab, AnalyticsFilterKeys, LD_FeatureFlags } from '../../types';
import { ANALYTICS_ACTIVE_TAB_PARAM } from '../../constants';
import { conditionalArray, conditionalObject } from '../../utils';
import { ProgressReportTab } from './ProgressReportTab';
import { TeamPerformanceTab } from './TeamPerformanceTab';

const DEFAULT_ACTIVE_TAB = AnalyticsTab.ACTIVITY;

const AnalyticsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTabFromParams = searchParams.get(ANALYTICS_ACTIVE_TAB_PARAM);
  const activeTab = currentTabFromParams || DEFAULT_ACTIVE_TAB;

  const analyticsV2FF = useFeatureFlag(LD_FeatureFlags.RELEASE_ANALYTICS_V2);
  const analyticsV3FF = useFeatureFlag(LD_FeatureFlags.RELEASE_ANALYTICS_V3);

  const tabs = useMemo(
    () => [
      {
        id: AnalyticsTab.ACTIVITY,
        title: 'Activity',
        content: <ActivityTab />,
      },

      ...conditionalArray(analyticsV3FF, {
        id: AnalyticsTab.TEAM_PERFORMANCE,
        title: 'Team performance',
        content: <TeamPerformanceTab />,
      }),
      ...conditionalArray(analyticsV2FF, {
        id: AnalyticsTab.PROGRESS_REPORT,
        title: 'Progress report',
        content: <ProgressReportTab />,
      }),
    ],
    [analyticsV2FF, analyticsV3FF]
  );

  const handleTabChange = useCallback(
    (newActiveTab: string) => {
      // Reset all filters except date range when changing tabs
      const dateRangeFromParams = searchParams.get(AnalyticsFilterKeys.DATE_RANGE);
      setSearchParams({
        ...conditionalObject(!!dateRangeFromParams, { [AnalyticsFilterKeys.DATE_RANGE]: dateRangeFromParams }),
        [ANALYTICS_ACTIVE_TAB_PARAM]: newActiveTab,
      } as Record<string, string>);
    },
    [searchParams, setSearchParams]
  );

  // Set default active tab if not set in URL
  useEffect(() => {
    if (!currentTabFromParams) {
      handleTabChange(DEFAULT_ACTIVE_TAB);
    }
  }, [currentTabFromParams, handleTabChange]);

  return (
    <div className="p4 h-full w-full">
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={handleTabChange} type={TabsType.BORDERED} />
    </div>
  );
};

export default AnalyticsPage;
