import * as RadixAccordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import React, { useState } from 'react';
import { ComponentSize, TextColor } from '../../../types';
import { Icon, IconComponent, Icons } from '../Icons';
import { Typography, TypographySize, TypographyWeight } from '../Typography';
import { AccordionProps, AccordionType } from './Accordion.types';
import AccordionContent from './AccordionContent';
import AccordionTrigger from './AccordionTrigger';

const Accordion = ({ items, className, type = AccordionType.SINGLE }: AccordionProps) => {
  const [singleActiveItem, setSingleActiveItem] = useState(items.find((item) => !item.disabled)?.id);
  const [multiActiveItems, setMultiActiveItems] = useState(
    items.filter((item) => !item.disabled).map((item) => item.id)
  );

  const renderIcon = (icon: Icon | IconComponent, color?: TextColor) => {
    const iconSize = ComponentSize.LARGE;
    return typeof icon === 'string' ? (
      <Icons icon={icon} size={iconSize} color={color} />
    ) : (
      React.cloneElement(icon, { size: iconSize, color, ...icon.props })
    );
  };

  const isSingle = type === AccordionType.SINGLE;

  const rootProps = isSingle
    ? { type, value: singleActiveItem, collapsible: true, onValueChange: setSingleActiveItem }
    : { type, value: multiActiveItems, onValueChange: setMultiActiveItems };

  return (
    <RadixAccordion.Root className={clsx('flex flex-col rounded-2xl bg-base-100', className)} {...rootProps}>
      {items.map(({ id, disabled, icon: iconProp, title, content, className: itemClassName }, index) => {
        const isActive = isSingle ? id === singleActiveItem : multiActiveItems.includes(id);
        const isLastItem = index === items.length - 1;
        const icon = disabled ? Icon.LOCK : iconProp;
        const textColor = disabled ? TextColor.SECONDARY : TextColor.PRIMARY;
        return (
          <RadixAccordion.Item
            className={clsx(
              'collapse rounded-none p-4',
              !isLastItem && 'border-b border-base-300',
              isLastItem && 'rounded-b-2xl',
              itemClassName
            )}
            value={id}
            key={id}
          >
            <AccordionTrigger isActive={isActive} disabled={disabled}>
              <div className="flex items-center gap-2">
                {icon && renderIcon(icon, textColor)}
                {typeof title === 'string' && (
                  <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD} color={textColor}>
                    {title}
                  </Typography>
                )}
                {typeof title === 'object' &&
                  React.cloneElement(title, {
                    color: textColor,
                    size: TypographySize.H3,
                    weight: TypographyWeight.SEMI_BOLD,
                    ...title.props,
                  })}
              </div>
            </AccordionTrigger>
            <AccordionContent>{content}</AccordionContent>
          </RadixAccordion.Item>
        );
      })}
    </RadixAccordion.Root>
  );
};

export default Accordion;
