import {
  ButtonColor,
  ButtonVariant,
  Icon,
  IconButton,
  TextArea,
  Typography,
  TypographySize,
} from '../../../../components';
import { TextColor } from '../../../../types';

const MIN_TEXT_AREA_ROWS = 1;

interface ScenarioListItemProps {
  index: number;
  canDelete: boolean;
  isViewMode: boolean;
  value: string;
  deleteItem: () => void;
  updateItem: (value: string) => void;
  isRequired?: boolean;
}

const ScenarioListItem = ({
  index,
  canDelete,
  isViewMode,
  value,
  deleteItem,
  updateItem,
  isRequired,
}: ScenarioListItemProps) => {
  return (
    <div key={index} className="flex items-center gap-2">
      <Typography mono color={TextColor.SECONDARY} size={TypographySize.CAPTION} className="select-none">
        {index + 1}
        {isRequired && !isViewMode ? '*' : '\u00A0'}
      </Typography>
      <TextArea
        autoSize
        disabled={isViewMode}
        rows={MIN_TEXT_AREA_ROWS}
        value={value}
        onChange={(e) => updateItem(e.target.value)}
      />
      {!isViewMode && (
        <IconButton
          color={ButtonColor.DESTRUCTIVE}
          disabled={!canDelete}
          icon={Icon.TRASH}
          variant={ButtonVariant.GHOST}
          onClick={deleteItem}
        />
      )}
    </div>
  );
};

export default ScenarioListItem;
