import { useCallback, useState } from 'react';
import { useSubmitLink } from '../../../hooks';
import { TextButton, TextInput } from '../../shared';
import { SubmitSuccess } from '../SubmitSuccess';

interface SubmitLinkProps {
  isAITwin?: boolean;
}

const SubmitLink = ({ isAITwin = false }: SubmitLinkProps) => {
  // State
  const [linkInput, setLinkInput] = useState('');
  const [error, setError] = useState('');
  const [isLinkSubmitted, setIsLinkSubmitted] = useState(false);

  // Mutations
  const { submitLink, isLoading: isSubmittingLink } = useSubmitLink(isAITwin);

  const onLinkChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkInput(e.target.value);
    setError('');
  }, []);

  const handleSubmitLink = useCallback(async () => {
    // If there is an error or the link is empty, return
    if (!linkInput || !!error) return;

    const result = await submitLink(linkInput);

    if (result?.error) {
      setError(result.error);
    } else {
      setLinkInput('');
      setIsLinkSubmitted(true);
    }
  }, [error, linkInput, submitLink]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSubmitLink();
      }
    },
    [handleSubmitLink]
  );

  if (isLinkSubmitted) {
    return <SubmitSuccess handleSubmitAnother={() => setIsLinkSubmitted(false)} />;
  }

  return (
    <div className="flex gap-2">
      <TextInput placeholder="https://" value={linkInput} onChange={onLinkChange} error={error} onKeyDown={onKeyDown} />
      <TextButton
        text="Submit"
        onClick={handleSubmitLink}
        disabled={!linkInput}
        loading={isSubmittingLink}
        className="mb-4"
      />
    </div>
  );
};

export default SubmitLink;
