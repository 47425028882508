import { useCallback, useEffect, useMemo } from 'react';
import {
  ButtonColor,
  findOptionByValue,
  Icon,
  Select,
  TextButton,
  Typography,
  TypographySize,
} from '../../../../components';
import { MIN_VISIBLE_HIDDEN_CONTEXT_ITEMS, PROSPECT_SLIDER_LIMITS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  addHiddenContext,
  deleteHiddenContext,
  randomizeCallContext,
  setLastTouchpoint,
  setSuccessDifficultyLevel,
  setSuccessResult,
  updateHiddenContext,
} from '../../../../redux/reducers';
import { ProspectLastTouchpoint, ProspectPageMode, ProspectSuccessResult, TextColor } from '../../../../types';
import { parseValuesToOptions } from '../../../../utils';
import ProspectFieldGroup from '../ProspectFieldGroup';
import ProspectPageField from '../ProspectPageField';
import ProspectSlider from '../ProspectSlider';
import TabSection from '../TabSection';
import ScenarioListItem from './ScenarioListItem';

const LAST_TOUCHPOINT_OPTIONS = parseValuesToOptions(Object.values(ProspectLastTouchpoint));
const SUCCESS_RESULT_OPTIONS = parseValuesToOptions(Object.values(ProspectSuccessResult));

const CallContext = () => {
  const dispatch = useAppDispatch();
  const {
    mode,
    fields: { lastTouchpoint, successResult, hiddenContexts, successDifficultyLevel },
  } = useAppSelector((state) => state.prospect);

  const isViewMode = mode === ProspectPageMode.VIEW;

  const selectedLastTouchpointOption = findOptionByValue(LAST_TOUCHPOINT_OPTIONS, lastTouchpoint.value);
  const selectedSuccessResultOption = findOptionByValue(SUCCESS_RESULT_OPTIONS, successResult.value);

  const handleLastTouchpointChange = useCallback(
    (value?: string) => {
      const newLastTouchpoint = value as ProspectLastTouchpoint;
      dispatch(setLastTouchpoint(newLastTouchpoint));
    },
    [dispatch]
  );

  const handleSuccessResultChange = useCallback(
    (value?: string) => {
      const newSuccessResult = value as ProspectSuccessResult;
      dispatch(setSuccessResult(newSuccessResult));
    },
    [dispatch]
  );

  const handleDifficultyChange = useCallback(
    (value: number) => {
      dispatch(setSuccessDifficultyLevel(value));
    },
    [dispatch]
  );

  const fieldGroup = useMemo(
    () => [
      {
        label: 'Last touchpoint',
        content: (
          <Select
            clearable
            selected={selectedLastTouchpointOption}
            options={LAST_TOUCHPOINT_OPTIONS}
            onChange={handleLastTouchpointChange}
          />
        ),
      },
      {
        label: 'Goal',
        required: true,
        content: (
          <Select
            clearable
            selected={selectedSuccessResultOption}
            options={SUCCESS_RESULT_OPTIONS}
            onChange={handleSuccessResultChange}
          />
        ),
      },
    ],
    [selectedLastTouchpointOption, selectedSuccessResultOption, handleLastTouchpointChange, handleSuccessResultChange]
  );

  // Delete empty hidden contexts on unmount.
  useEffect(() => {
    return () => {
      if (hiddenContexts.length > MIN_VISIBLE_HIDDEN_CONTEXT_ITEMS) {
        hiddenContexts.forEach((context, index) => {
          if (!context) {
            dispatch(deleteHiddenContext(index));
          }
        });
      }
    };
  }, []);

  return (
    <TabSection title="Call context" onRandomize={() => dispatch(randomizeCallContext())}>
      <ProspectFieldGroup fields={fieldGroup} />
      <div className="flex flex-col gap-2">
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
          Hidden context
        </Typography>
        <div className="flex flex-col gap-4">
          {hiddenContexts.map((item, index) => (
            <ScenarioListItem
              key={index}
              index={index}
              canDelete={hiddenContexts.length > MIN_VISIBLE_HIDDEN_CONTEXT_ITEMS}
              isViewMode={isViewMode}
              value={item}
              deleteItem={() => dispatch(deleteHiddenContext(index))}
              updateItem={(value) => dispatch(updateHiddenContext({ index, value }))}
            />
          ))}
          {!isViewMode && (
            <TextButton
              text="Add context"
              startIcon={Icon.PLUS}
              color={ButtonColor.SECONDARY}
              onClick={() => {
                dispatch(addHiddenContext());
              }}
            />
          )}
        </div>
      </div>
      <ProspectPageField
        label="Difficulty"
        content={
          <ProspectSlider
            hideProgress
            leftLabel="Easy"
            rightLabel="Difficult"
            max={PROSPECT_SLIDER_LIMITS.max}
            min={PROSPECT_SLIDER_LIMITS.min}
            step={PROSPECT_SLIDER_LIMITS.step}
            value={successDifficultyLevel.value}
            onChange={handleDifficultyChange}
          />
        }
      />
    </TabSection>
  );
};

export default CallContext;
