import { useCallback, useMemo } from 'react';
import { ButtonColor, ButtonGroup, DesignerModalCard, Dialog, TextButton, Typography } from '../../../components';
import { ComponentSize, ScorecardTemplateType, TextColor } from '../../../types';

const DESIGNER_MODAL_WIDTH = 485;

interface ScorecardTemplateDesignerModalProps {
  scorecardType: ScorecardTemplateType;
  isModalOpen: boolean;
  setScorecardType: React.Dispatch<React.SetStateAction<ScorecardTemplateType>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel: () => void;
}

const ScorecardTemplateDesignerModal = ({
  scorecardType,
  setScorecardType,
  isModalOpen,
  setIsModalOpen,
  onCancel,
}: ScorecardTemplateDesignerModalProps) => {
  const options = useMemo(
    () => [
      {
        id: ScorecardTemplateType.AI,
        isSelected: scorecardType === ScorecardTemplateType.AI,
        title: 'AI scorecard',
        description: 'Automatically scored using AI',
        onSelect: () => setScorecardType(ScorecardTemplateType.AI),
      },
      {
        id: ScorecardTemplateType.MANUAL,
        isSelected: scorecardType === ScorecardTemplateType.MANUAL,
        title: 'Manual scorecard',
        description: 'Completed manually by a user',
        onSelect: () => setScorecardType(ScorecardTemplateType.MANUAL),
      },
    ],
    [scorecardType]
  );

  // Closes the designer modal.
  const onCreate = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <Dialog isOpen={isModalOpen} title="Scorecard designer" width={DESIGNER_MODAL_WIDTH} onClose={onCancel}>
      <div className="flex flex-col gap-4">
        {options.map((option) => (
          <DesignerModalCard key={option.id} {...option} />
        ))}
        <Typography color={TextColor.SECONDARY}>
          Note: these cannot be changed. You must create a new scorecard to change these properties.
        </Typography>
        <ButtonGroup size={ComponentSize.MEDIUM}>
          <TextButton onClick={onCreate} text="Next" color={ButtonColor.PRIMARY} />
          <TextButton onClick={onCancel} text="Cancel" />
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export default ScorecardTemplateDesignerModal;
