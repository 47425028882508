import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../../constants';
import { TextColor } from '../../../../../types';
import { TextButton, Typography, TypographySize } from '../../../../shared';

interface SimulationDesignerItemProps {
  onClose: () => void;
}

const SimulationDesignerItem = ({ onClose }: SimulationDesignerItemProps) => {
  const navigate = useNavigate();

  // Close the create prospect modal and navigate to the prospect page.
  const handleStartDesigner = () => {
    onClose();
    navigate(AppRoutes.PROSPECT);
  };

  return (
    <div className="flex items-center justify-between">
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        Build your own simulation using a guided wizard.
      </Typography>
      <TextButton text="Start designer" onClick={handleStartDesigner} />
    </div>
  );
};

export default SimulationDesignerItem;
