import { EmptyState, Icon } from '../../components';

interface QuizPageProps {
  children: JSX.Element;
  filtersApplied: boolean;
  isEmpty: boolean;
}

const QuizPage = ({ children, filtersApplied, isEmpty }: QuizPageProps) => {
  if (isEmpty) {
    return (
      <EmptyState
        description={filtersApplied ? 'No quizzes found for the selected filters' : 'No quizzes created yet'}
        icon={Icon.QUIZ}
      />
    );
  }

  return children;
};

export default QuizPage;
