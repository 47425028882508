import { useCallback, useMemo, useState } from 'react';
import { useHandleApiResponse, useToast } from '../../../hooks';
import { useCreateTagMutation, useGetTagsQuery } from '../../../services';
import { DropdownPlacement, TagColor, TextColor } from '../../../types';
import {
  AlertType,
  Dropdown,
  DropdownContent,
  DropdownTrigger,
  Icon,
  MultiSelect,
  TextArea,
  Typography,
} from '../../shared';
import { ManageTagsAndNotesProps } from './ManageTagsAndNotes.types';
import ManageTagsAndNotesTrigger from './ManageTagsAndNotesTrigger';

const CREATE_TAG_OPTION = 'CREATE_TAG_OPTION';
const MAX_NOTES_ROWS = 5;
const MIN_NOTES_ROWS = 2;

const ManageTagsAndNotes = ({
  isOpen,
  itemTags,
  setIsOpen,
  onClose,
  isLoading,
  inline,
  notes,
  selectedTags,
  setNotes,
  setSelectedTags,
}: ManageTagsAndNotesProps) => {
  const [searchValue, setSearchValue] = useState('');

  const { data: tags = [], isLoading: isTagsLoading } = useGetTagsQuery();
  const [createTag, { isLoading: isCreatingTag }] = useCreateTagMutation();

  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  const canManageNotes = !!setNotes;

  const options = tags.map((tag) => ({ color: tag.color, label: tag.name, value: tag.id }));

  // The selected tags in the dropdown parsed into SelectOption format.
  const selectedOptions = useMemo(
    () => selectedTags.map((tag) => ({ label: tag.name, value: tag.id })),
    [selectedTags]
  );

  const handleCreateTag = useCallback(async () => {
    if (!searchValue.length) return;

    try {
      const randomColor = Object.values(TagColor)[Math.floor(Math.random() * Object.values(TagColor).length)];
      const response = await createTag({ name: searchValue, color: randomColor });
      handleApiResponse({
        response,
        errorMsg: `Failed to create tag "${searchValue}"`,
      });
    } catch (error) {
      showToast({ message: `Failed to create tag "${searchValue}"`, type: AlertType.ERROR });
      console.error(`Failed to create tag "${searchValue}": `, error);
    }
  }, [searchValue, createTag, showToast, handleApiResponse]);

  const onMultiSelectChange = useCallback(
    (newValues?: string[]) => {
      const newTags = tags.filter((tag) => newValues?.includes(tag.id));
      setSelectedTags(newTags);
    },
    [tags]
  );

  const renderContent = () => (
    <div className="flex flex-col gap-2">
      <MultiSelect
        inline
        options={options}
        selected={selectedOptions}
        onChange={onMultiSelectChange}
        disabled={isTagsLoading}
        searchProps={{
          searchValue,
          setSearchValue,
          placeholder: 'Type to filter or create',
          disabled: isLoading || isCreatingTag,
          createOption: {
            icon: Icon.PLUS,
            label: `Create new tag "${searchValue}"`,
            value: CREATE_TAG_OPTION,
            onClick: handleCreateTag,
          },
        }}
        width="w-fit"
      />
      {canManageNotes && (
        <>
          <Typography className="px-2" color={TextColor.SECONDARY}>
            Notes
          </Typography>
          <TextArea
            autoSize
            maxRows={MAX_NOTES_ROWS}
            rows={MIN_NOTES_ROWS}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Type to add notes"
          />
        </>
      )}
    </div>
  );

  if (inline) {
    return renderContent();
  }

  return (
    <Dropdown
      open={isOpen}
      placement={DropdownPlacement.BOTTOM_START}
      onOpenChange={(open) => {
        setIsOpen(open);
        if (!open) onClose();
      }}
    >
      <DropdownTrigger>
        <ManageTagsAndNotesTrigger
          active={isOpen}
          canManageNotes={canManageNotes}
          showBadge={!itemTags?.length}
          onClick={() => setIsOpen(true)}
        />
      </DropdownTrigger>
      <DropdownContent>{renderContent()}</DropdownContent>
    </Dropdown>
  );
};

export default ManageTagsAndNotes;
