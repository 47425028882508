import { useCallback } from 'react';
import { useHandleApiResponse, useToast, useUpdateOrgSeats } from '../../../../../../hooks';
import { useRevokeInvitationMutation } from '../../../../../../services';
import { TableUser } from '../../../../../../types';
import { AlertType } from '../../../../../shared';
import ConfirmModal from '../../../../ConfirmModal';

const ERROR_MSG = 'Failed to revoke invitation';

interface RevokeConfirmModalProps {
  user: TableUser;
  closeModal: () => void;
}

const RevokeConfirmModal = ({ user, closeModal }: RevokeConfirmModalProps) => {
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();
  const updateOrgSeats = useUpdateOrgSeats();

  const [revokeInvitation, { isLoading }] = useRevokeInvitationMutation();

  // Handles revoking an invitation.
  const handleRevokeInvitation = useCallback(async () => {
    try {
      const response = await revokeInvitation({ id: user.id });
      const onSuccess = () => {
        closeModal();
        updateOrgSeats(user.role);
      };
      handleApiResponse({ response, errorMsg: ERROR_MSG, onSuccess });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [handleApiResponse, showToast, updateOrgSeats, user.role, closeModal]);

  return (
    <ConfirmModal
      buttonText="Revoke"
      isOpen
      setIsOpen={closeModal}
      onConfirm={handleRevokeInvitation}
      isLoading={isLoading}
      title="Confirm revoke invitation"
      destructive
      confirmText={
        <>
          Are you sure you want revoke the invitation sent to&nbsp;
          <span className="font-medium">{user.name}</span>?
        </>
      }
    />
  );
};

export default RevokeConfirmModal;
