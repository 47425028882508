import { useCallback, useMemo } from 'react';
import { findOptionByValue, Select } from '../../../../components';
import { ANNUAL_REVENUE_RANGES, COMPANY_SIZE_RANGES, FUNDING_RAISED_OPTIONS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setAnnualRevenue, setFundingRaisedType, setNumOfEmployees } from '../../../../redux/reducers';
import { FundingRaised, RangeType } from '../../../../types';
import ProspectFieldGroup from '../ProspectFieldGroup';

const COMPANY_SIZE_OPTIONS = COMPANY_SIZE_RANGES.map(({ label, value }) => ({ label, value: value.join(',') }));
const ANNUAL_REVENUE_OPTIONS = ANNUAL_REVENUE_RANGES.map(({ label, value }) => ({ label, value: value.join(',') }));

const parseStrToRange = (value?: string): RangeType | undefined => {
  const newArr = value?.split(',');
  if (!newArr) return undefined;

  const [from, to] = newArr;
  return [Number(from), to ? Number(to) : undefined];
};

const ResourceFields = () => {
  const dispatch = useAppDispatch();
  const { employeesFrom, employeesTo, annualRevenueFrom, annualRevenueTo, fundingRaisedType } = useAppSelector(
    (state) => state.prospect.fields
  );

  const selectedNumOfEmployeesOption = COMPANY_SIZE_OPTIONS.find(
    (option) => option.value === [employeesFrom.value, employeesTo].join(',')
  );
  const selectedAnnualRevenueOption = ANNUAL_REVENUE_OPTIONS.find(
    (option) => option.value === [annualRevenueFrom.value, annualRevenueTo].join(',')
  );
  const selectedFundingRaisedOption = findOptionByValue(FUNDING_RAISED_OPTIONS, fundingRaisedType.value);

  const handleNumOfEmployeesChange = useCallback(
    (value?: string) => dispatch(setNumOfEmployees(parseStrToRange(value))),
    [dispatch]
  );

  const handleAnnualRevenueChange = useCallback(
    (value?: string) => dispatch(setAnnualRevenue(parseStrToRange(value))),
    [dispatch]
  );

  const handleFundingRaisedChange = useCallback(
    (value?: string) => {
      const newFundingRaised = value as FundingRaised;
      dispatch(setFundingRaisedType(newFundingRaised));
    },
    [dispatch]
  );

  const fields = useMemo(
    () => [
      {
        label: '# of employees',
        content: (
          <Select
            clearable
            options={COMPANY_SIZE_OPTIONS}
            selected={selectedNumOfEmployeesOption}
            onChange={handleNumOfEmployeesChange}
          />
        ),
      },
      {
        label: 'Annual revenue',
        content: (
          <Select
            clearable
            options={ANNUAL_REVENUE_OPTIONS}
            selected={selectedAnnualRevenueOption}
            onChange={handleAnnualRevenueChange}
          />
        ),
      },
      {
        label: 'Entity type',
        content: (
          <Select
            clearable
            options={FUNDING_RAISED_OPTIONS}
            selected={selectedFundingRaisedOption}
            onChange={handleFundingRaisedChange}
          />
        ),
      },
    ],
    [
      selectedNumOfEmployeesOption,
      selectedAnnualRevenueOption,
      selectedFundingRaisedOption,
      handleNumOfEmployeesChange,
      handleAnnualRevenueChange,
      handleFundingRaisedChange,
    ]
  );

  return <ProspectFieldGroup fields={fields} />;
};

export default ResourceFields;
