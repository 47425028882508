import { AVATAR_BG_COLOR_VALUES } from './Avatar.constants';

/** Converts a string to a hash value. */
export function stringToHash(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // Get the character code at the current index.
    const char = str.charCodeAt(i);
    // Bitwise shift and add the character code.
    hash = (hash << 5) - hash + char;
    // Convert to 32-bit integer.
    hash |= 0;
  }
  return hash;
}

/** Function to generate default color based on a string. */
export function stringToColor(str?: string) {
  // Return default color if the input string is not provided or if it starts with a plus sign.
  if (!str?.length || str.charAt(0) === '+') {
    return AVATAR_BG_COLOR_VALUES.gray;
  }

  // Generate a numerical hash from the first three characters of the string.
  const numericalHash = stringToHash(str.substring(0, 3)) % Object.keys(AVATAR_BG_COLOR_VALUES).length;

  // Return the corresponding color values from the AVATAR_BG_COLOR_VALUES array.
  return Object.values(AVATAR_BG_COLOR_VALUES)[numericalHash];
}
