import { useRef } from 'react';
import { useDetectTextOverflow } from '../../../hooks';
import { ComponentSize, DropdownPlacement, TextColor } from '../../../types';
import { Avatar } from '../Avatar';
import { Typography } from '../Typography';
import { USER_BADGE_SUBTITLE_SIZE, USER_BADGE_TITLE_SIZE } from './UserBadge.constants';
import { UserBadgeProps } from './UserBadge.types';

const MAX_LINES = 1;

const UserBadge = ({
  title,
  imgSrc,
  size = ComponentSize.SMALL,
  subtitle = '',
  hideAvatarIfNoImgSrc,
}: UserBadgeProps) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const isTitleOverflowing = useDetectTextOverflow(titleRef, MAX_LINES);

  const subtitleRef = useRef<HTMLDivElement>(null);
  const isSubtitleOverflowing = useDetectTextOverflow(subtitleRef, MAX_LINES);

  const getTooltip = (text: string) => ({ content: text, placement: DropdownPlacement.BOTTOM });

  return (
    <div className="flex items-center gap-2">
      {(!hideAvatarIfNoImgSrc || imgSrc) && <Avatar label={title} size={size} imgSrc={imgSrc} />}
      <div className="flex flex-col gap-1">
        <Typography
          size={USER_BADGE_TITLE_SIZE[size]}
          ref={titleRef}
          maxLines={MAX_LINES}
          tooltip={isTitleOverflowing ? getTooltip(title) : undefined}
          className="truncate"
        >
          {title}
        </Typography>
        <Typography
          size={USER_BADGE_SUBTITLE_SIZE[size]}
          color={TextColor.SECONDARY}
          ref={subtitleRef}
          maxLines={MAX_LINES}
          tooltip={isSubtitleOverflowing ? getTooltip(subtitle) : undefined}
          className="truncate break-all"
        >
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};

export default UserBadge;
