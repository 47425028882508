import { PracticeProspectsFilters } from './prospect.types';

export enum Page {
  PRACTICE_PROSPECTS = 'PRACTICE_PROSPECTS',
}

export interface UpdateSavedViewPayload {
  page: Page.PRACTICE_PROSPECTS;
  filters: PracticeProspectsFilters;
}
