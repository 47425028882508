import { useEffect } from 'react';
import { DropdownPlacement, TextColor } from '../../../types';
import { ButtonColor, ButtonVariant, IconButton } from '../Button';
import { Dropdown, DropdownContent, DropdownTrigger } from '../Dropdown';
import { DropdownItem } from '../DropdownItem';
import { Icon, Icons } from '../Icons';
import { Typography } from '../Typography';
import { RowEndAction } from './DataTable.types';

interface DataTableActionsCellProps<TData> {
  data: TData;
  isOpen: boolean;
  setIsActive: (active: boolean) => void;
  setIsOpen: (open: boolean) => void;
  useEndActions: (row: TData, closeActions: () => void) => RowEndAction[];
}

const DataTableActionsCell = <TData,>({
  data,
  isOpen,
  setIsActive,
  setIsOpen,
  useEndActions,
}: DataTableActionsCellProps<TData>) => {
  const actions = useEndActions(data, () => setIsOpen(false));
  const isDisabled = !actions.length;

  // Set active row index when dropdown is open.
  // Handles the case where the dropdown is opened by a right click.
  useEffect(() => {
    if (!isDisabled && isOpen) {
      setIsActive(true);
    }
  }, [isDisabled, isOpen]);

  return (
    <Dropdown
      disabled={isDisabled}
      open={!isDisabled && isOpen}
      onOpenChange={(open) => {
        if (!open) {
          setIsOpen(false);
          setIsActive(false);
        }
      }}
      placement={DropdownPlacement.BOTTOM_END}
    >
      <DropdownTrigger>
        <IconButton
          active={isOpen}
          color={ButtonColor.SECONDARY}
          disabled={isDisabled}
          icon={Icon.ELLIPSIS_VERTICAL}
          variant={ButtonVariant.OUTLINE}
          onClick={() => setIsOpen(true)}
        />
      </DropdownTrigger>
      <DropdownContent>
        {actions.map(({ destructive, icon, label, ...props }) => {
          const textColor = destructive ? TextColor.DESTRUCTIVE : TextColor.PRIMARY;
          return (
            <DropdownItem
              key={label}
              destructive={destructive}
              icon={<Icons icon={icon} color={textColor} />}
              label={<Typography color={textColor}>{label}</Typography>}
              {...props}
            />
          );
        })}
      </DropdownContent>
    </Dropdown>
  );
};

export default DataTableActionsCell;
