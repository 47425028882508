import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PaginationType } from '../../../types';
import { Skeleton } from '../Skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table';
import { DATA_TABLE_DEFAULT_CONTENT_HEIGHT, DATA_TABLE_Y_PADDING } from './DataTable.constants';
import { DataTableProps, WithDisabled } from './DataTable.types';
import DataTableActionsCell from './DataTableActionsCell';
import PaginationControls from './PaginationControls';

const END_ACTIONS_COLUMN_ID = 'endActions';

const getSizeStyles = (size: number) => {
  // If the size is an integer, then it is a number of pixels.
  // If the size is a float, then it is a percentage of the table width.
  // This is because TanStack Table only supports number sizes.
  const isInteger = size % 1 === 0;
  return {
    width: size === 0 ? 'auto' : isInteger ? size : `${size * 100}%`,
    minWidth: isInteger ? size : undefined,
  };
};

/** Generic DataTable component designed to handle different data types with configurable columns. */
function DataTable<TData>({
  activeRowIndices: activeRowIndicesProp = [],
  columns: columnsProp,
  data,
  tableClassName,
  contentHeight: contentHeightProp,
  isLoading,
  paginationControls,
  onRowClick,
  onRowHover,
  onRowRightClick,
  useEndActions,
}: DataTableProps<TData & WithDisabled>) {
  const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null);
  const [openEndActionsIndex, setOpenEndActionsIndex] = useState<number | null>(null);
  const [localPagination, setLocalPagination] = useState(paginationControls);

  const contentHeight = contentHeightProp ?? DATA_TABLE_DEFAULT_CONTENT_HEIGHT;
  // + 1 for the bottom border.
  const rowHeight = contentHeight + DATA_TABLE_Y_PADDING * 2 + 1;

  useEffect(() => {
    if (!isLoading && paginationControls) {
      setLocalPagination(paginationControls);
    }
  }, [isLoading, paginationControls]);

  // Add end actions column to the table.
  const columns = useMemo(() => {
    const baseColumns = [...columnsProp];
    if (useEndActions) {
      baseColumns.push({
        accessorKey: END_ACTIONS_COLUMN_ID,
        header: '',
        cell: ({ row }) => (
          <DataTableActionsCell
            data={row.original}
            isOpen={openEndActionsIndex === row.index}
            setIsActive={(active) => setActiveRowIndex(active ? row.index : null)}
            setIsOpen={(isOpen) => setOpenEndActionsIndex(isOpen ? row.index : null)}
            useEndActions={useEndActions}
          />
        ),
      });
    }
    return baseColumns;
  }, [columnsProp, useEndActions, openEndActionsIndex]);

  // Initialize the table with configuration for columns and data.
  const table = useReactTable({
    data,
    columns,
    manualPagination: true, // Manual "server-side" pagination
    getCoreRowModel: getCoreRowModel(), // Function to generate a row model from provided data.
    onPaginationChange: localPagination?.setPagination,
    state: {
      pagination: localPagination?.pagination,
    },
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
  });

  const colSpan = columns.length;
  const rows = table.getRowModel().rows;

  // Renders table rows based on loading state and data availability.
  const renderRows = useCallback(() => {
    if (!rows.length) {
      return (
        <TableRow index={0} height={rowHeight}>
          <TableCell colSpan={colSpan}>No results.</TableCell>
        </TableRow>
      );
    }

    return rows.map((row, index) => {
      const isActive = [...activeRowIndicesProp, activeRowIndex].includes(index);
      const isDisabled = row.original.disabled;
      return (
        <TableRow
          key={row.id}
          index={index}
          data-state={row.getIsSelected() && 'selected'}
          active={isActive}
          disabled={isDisabled}
          height={rowHeight}
          onRowClick={onRowClick}
          onRowHover={onRowHover}
          onRowRightClick={onRowRightClick}
          setOpenEndActionsIndex={setOpenEndActionsIndex}
        >
          {row.getVisibleCells().map((cell) => (
            <TableCell
              key={cell.id}
              className={clsx(isDisabled && cell.column.id !== END_ACTIONS_COLUMN_ID && 'opacity-50')}
              style={getSizeStyles(cell.column.getSize())}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          ))}
        </TableRow>
      );
    });
  }, [
    rowHeight,
    rows,
    colSpan,
    activeRowIndicesProp,
    activeRowIndex,
    onRowClick,
    onRowHover,
    onRowRightClick,
    openEndActionsIndex,
    setOpenEndActionsIndex,
  ]);

  // If both onNextClick and onPrevClick are provided, then it is cursor-based.
  // @tanstack/react-table doesn't have built-in support for cursor-based pagination.
  // We need to handle it manually in case the pagination is cursor-based.
  const isCursorPagination = !!(paginationControls?.onNextClick && paginationControls?.onPrevClick);
  const paginationType = isCursorPagination ? PaginationType.CURSOR : PaginationType.PAGE;

  // Intionally not ternary condition to not evaluate the expression based on the return value of the function itself.
  const handlePrevClick = () => {
    if (paginationControls?.onPrevClick) {
      return paginationControls.onPrevClick();
    } else {
      return table.previousPage();
    }
  };

  // Intionally not ternary condition to not evaluate the expression based on the return value of the function itself.
  const handleNextClick = () => {
    if (paginationControls?.onNextClick) {
      return paginationControls.onNextClick();
    } else {
      return table.nextPage();
    }
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <Table className={tableClassName}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} index={0}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan} style={getSizeStyles(header.getSize())}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {isLoading && (
            <TableRow index={0}>
              <TableCell colSpan={colSpan}>
                <Skeleton size={contentHeight} />
              </TableCell>
            </TableRow>
          )}
          {!isLoading && renderRows()}
        </TableBody>
      </Table>
      {!!localPagination && localPagination.totalPages > 1 && (
        <PaginationControls
          onPrevClick={handlePrevClick}
          onNextClick={handleNextClick}
          pagination={localPagination.pagination}
          setPagination={localPagination.setPagination}
          totalPages={localPagination.totalPages}
          paginationType={paginationType}
        />
      )}
    </div>
  );
}

export default DataTable;
