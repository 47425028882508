import clsx from 'clsx';
import React from 'react';

// Interface for TableRow props extending HTML attributes for a table row.
interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  active?: boolean;
  disabled?: boolean;
  height?: number;
  onRowClick?: (e: React.MouseEvent, index: number) => void;
  onRowHover?: (index?: number) => void;
  onRowRightClick?: (e: React.MouseEvent, index: number) => void;
  setOpenEndActionsIndex?: (index: number) => void;
}

const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(
  (
    {
      active,
      className,
      disabled,
      height,
      index,
      onRowClick,
      onRowHover,
      onRowRightClick,
      setOpenEndActionsIndex,
      ...props
    },
    ref
  ) => {
    const handleRowHover = onRowHover ? (hover: boolean) => onRowHover(hover ? index : undefined) : undefined;

    const handleRowClick = onRowClick
      ? (e: React.MouseEvent<Element, MouseEvent>) => {
          onRowClick(e, index);
          if (onRowHover) {
            onRowHover(undefined);
          }
        }
      : undefined;

    const handleRowRightClick =
      onRowRightClick || setOpenEndActionsIndex
        ? (e: React.MouseEvent<Element, MouseEvent>) => {
            // Prevent the default context menu from appearing.
            e.preventDefault();
            e.stopPropagation();

            // If the row right click handler is provided, call it.
            if (onRowRightClick) {
              onRowRightClick(e, index);
            } else if (setOpenEndActionsIndex) {
              // Otherwise, open row end actions.
              setOpenEndActionsIndex(index);
            }

            if (onRowHover) {
              onRowHover(undefined);
            }
          }
        : undefined;

    const eventHandlers = {
      onClick: handleRowClick,
      onMouseEnter: handleRowHover ? () => handleRowHover(true) : undefined,
      onMouseLeave: handleRowHover ? () => handleRowHover(false) : undefined,
    };

    return (
      <tr
        ref={ref}
        className={clsx(active && 'active !bg-base-100', !disabled && onRowClick && 'hover cursor-pointer', className)}
        style={{ height }}
        onContextMenu={handleRowRightClick}
        {...(disabled ? {} : eventHandlers)}
        {...props}
      />
    );
  }
);

TableRow.displayName = 'TableRow';

export default TableRow;
