import clsx from 'clsx';
import React from 'react';
import { Avatar } from '../Avatar';
import { AvatarGroupProps } from './AvatarGroup.types';

const AvatarGroup = ({ children, className, size, maxVisibleAvatars = 2 }: AvatarGroupProps) => {
  // Convert children to an array if it's not already an array
  const avatars = Array.isArray(children) ? children : [children];
  // Get the first maxVisibleAvatars number of avatars
  const visibleAvatars = avatars.slice(0, maxVisibleAvatars);
  // Get the number of remaining avatars
  const remainingCount = Math.max(0, avatars.length - maxVisibleAvatars);

  return (
    <div className={clsx('flex -space-x-5 rtl:space-x-reverse', className)}>
      {visibleAvatars.map((child) => React.cloneElement(child, { ring: true, size }))}
      {remainingCount > 0 && <Avatar label={remainingCount} size={size} ring />}
    </div>
  );
};

export default AvatarGroup;
