import { Select, TextInput, TextInputType } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  randomizeLead,
  setDepartment,
  setJobTitle,
  setNumOfDirectReports,
  setRoleType,
  setTenure,
} from '../../../redux/reducers';
import { ProspectRoleType } from '../../../types';
import { handleNumberInputChange, snakeCaseToLabel } from '../../../utils';
import ProspectFieldGroup from './ProspectFieldGroup';
import TabSection from './TabSection';

const LEAD_ROLE_TYPE_SELECT_WIDTH = 200;
const LEAD_NUMBER_FIELD_WIDTH = 120;
const ROLE_TYPE_OPTIONS = Object.values(ProspectRoleType).map((value) => ({ label: snakeCaseToLabel(value), value }));

const LeadTab = () => {
  const dispatch = useAppDispatch();
  const { jobTitle, tenure, roleType, department, numOfDirectReports } = useAppSelector(
    (state) => state.prospect.fields
  );

  const selectedRoleTypeOption = ROLE_TYPE_OPTIONS.find((option) => option.value === roleType.value);

  const handleJobTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newJobTitle = e.target.value;
    dispatch(setJobTitle(newJobTitle));
  };

  const handleRoleTypeChange = (value?: string) => {
    const newRoleType = value as ProspectRoleType;
    dispatch(setRoleType(newRoleType));
  };

  const handleDepartmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDepartment = e.target.value;
    dispatch(setDepartment(newDepartment));
  };

  const firstRowFields = [
    {
      fullWidth: true,
      label: 'Job title',
      required: true,
      content: <TextInput value={jobTitle.value} onChange={handleJobTitleChange} />,
    },
    {
      label: 'Tenure',
      width: LEAD_NUMBER_FIELD_WIDTH,
      content: (
        <TextInput
          type={TextInputType.NUMBER}
          value={tenure?.value?.toString()}
          onChange={handleNumberInputChange(dispatch, setTenure)}
        />
      ),
    },
    {
      label: 'Role type',
      width: LEAD_ROLE_TYPE_SELECT_WIDTH,
      content: (
        <Select
          clearable
          options={ROLE_TYPE_OPTIONS}
          selected={selectedRoleTypeOption}
          onChange={handleRoleTypeChange}
        />
      ),
    },
  ];

  const secondRowFields = [
    {
      fullWidth: true,
      label: 'Department',
      content: <TextInput value={department.value} onChange={handleDepartmentChange} />,
    },
    {
      label: '# of direct reports',
      width: LEAD_NUMBER_FIELD_WIDTH,
      content: (
        <TextInput
          type={TextInputType.NUMBER}
          value={numOfDirectReports?.value?.toString()}
          onChange={handleNumberInputChange(dispatch, setNumOfDirectReports)}
        />
      ),
    },
  ];

  return (
    <TabSection title="Lead" onRandomize={() => dispatch(randomizeLead())}>
      <ProspectFieldGroup fields={firstRowFields} />
      <ProspectFieldGroup fields={secondRowFields} />
    </TabSection>
  );
};

export default LeadTab;
