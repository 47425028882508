export enum LineChartKeys {
  DATE = 'date',
  AVG = 'avg',
}

export type AxisConfig = {
  label?: string;
  formatter?: (value: number) => string;
  tooltipLabel?: string;
};

export type LineChartDataPoint = {
  [LineChartKeys.DATE]: string;
  [LineChartKeys.AVG]: number;
};

export type Benchmark = {
  value: number;
  label: string;
};

export interface LineChartProps {
  /** The data to display in the bar chart */
  data: LineChartDataPoint[];
  /** The configuration for the x-axis */
  xAxis: AxisConfig;
  /** The configuration for the y-axis */
  yAxis: AxisConfig;
  /** The chart's benchmark */
  benchmark?: Benchmark;
}
