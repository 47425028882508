import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetOrganizationSettingsQuery, useGetCallMetricAnalyticsMutation } from '../../../services';
import { ProgressReportCard } from './ProgressReportCard';
import { AnalyticsFilters, AverageByPeriod, CallMetric, DateFormat } from '../../../types';
import { LineChartKeys } from '../../../components';
import { formatSecondsToDuration } from '../../../utils';
import dayjs from 'dayjs';

interface CallMetricChartProps {
  filters: AnalyticsFilters;
}

const CallMetricChart = ({ filters }: CallMetricChartProps) => {
  // Set default selected metric to words per minute
  const [selectedMetric, setSelectedMetric] = useState<CallMetric>(CallMetric.TALK_SPEED);

  const { data: orgConfigs } = useGetOrganizationSettingsQuery();
  const { wordsPerMinute, fillerWordsPerMinute, longestMonologue, talkRatio } = orgConfigs || {};

  // Convert talk ratio to decimal for benchmark because data points are in decimal
  const talkRatioBenchmarkIndecimal = talkRatio ? talkRatio / 100 : undefined;
  const formattedLongestMonologueBenchmark = longestMonologue ? formatSecondsToDuration(longestMonologue) : undefined;

  const [
    getCallMetricAnalytics,
    { data: { stats: callMetricAnalytics = [], overallAverage = null } = {}, isLoading: isLoadingCallMetricAnalytics },
  ] = useGetCallMetricAnalyticsMutation();

  const fetchCallMetricAnalytics = useCallback(() => {
    if (!filters.userId || !filters.groupBy || !filters.dateRange) return;

    getCallMetricAnalytics({
      ...filters,
      dateRange: [filters.dateRange],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      groupBy: filters.groupBy[0] as AverageByPeriod,
      userId: filters.userId[0],
      metric: selectedMetric,
    });
  }, [getCallMetricAnalytics, filters, selectedMetric]);

  // Fetch call metric analytics
  useEffect(() => {
    fetchCallMetricAnalytics();
  }, [fetchCallMetricAnalytics]);

  const callMetricOptions = useMemo(
    () => [
      {
        label: 'Talk speed',
        value: CallMetric.TALK_SPEED,
        unit: 'words per minute',
        formatter: (value: number) => `${Math.round(value)}`,
        benchmark: wordsPerMinute ? { value: wordsPerMinute, label: `Benchmark: ${wordsPerMinute}` } : undefined,
      },
      {
        label: 'Filler words',
        value: CallMetric.FILLER_WORDS,
        unit: 'filler words per minute',
        formatter: (value: number) => `${Math.round(value)}`,
        benchmark: fillerWordsPerMinute
          ? { value: fillerWordsPerMinute, label: `Benchmark: ${fillerWordsPerMinute}` }
          : undefined,
      },
      {
        label: 'Longest monologue',
        value: CallMetric.LONGEST_MONOLOGUE,
        unit: 'minutes : seconds',
        formatter: (value: number) => formatSecondsToDuration(value),
        benchmark: longestMonologue
          ? { value: longestMonologue, label: `Benchmark: ${formattedLongestMonologueBenchmark}` }
          : undefined,
      },
      {
        label: 'Talk ratio',
        value: CallMetric.TALK_RATIO,
        unit: 'representative speaking',
        formatter: (value: number) => `${Math.round(value * 100)}%`,
        benchmark: talkRatioBenchmarkIndecimal
          ? { value: talkRatioBenchmarkIndecimal, label: `Benchmark: ${talkRatio}%` }
          : undefined,
      },
    ],
    [
      wordsPerMinute,
      fillerWordsPerMinute,
      longestMonologue,
      talkRatioBenchmarkIndecimal,
      formattedLongestMonologueBenchmark,
      talkRatio,
    ]
  );

  // Find selected metric
  const selectedMetricDetails = callMetricOptions.find((metric) => metric.value === selectedMetric);
  const { label, unit, benchmark, formatter } = selectedMetricDetails || {};

  // Transform call metric analytics data
  const callMetricData = callMetricAnalytics
    .filter((item) => item.metricValue !== undefined)
    .map((item) => ({
      [LineChartKeys.DATE]: item.date,
      [LineChartKeys.AVG]: item.metricValue,
    }));

  return (
    <ProgressReportCard
      title={`Average ${label?.toLowerCase()}`}
      valueUnit={unit}
      value={overallAverage ? `${formatter?.(overallAverage)}` : undefined}
      data={callMetricData}
      xAxis={{ formatter: (value) => dayjs(value).format(DateFormat.MONTH_DAY) }}
      yAxis={{
        label,
        tooltipLabel: label,
        formatter,
      }}
      selectOptions={callMetricOptions}
      selectedOption={selectedMetricDetails}
      onSelectOption={(value) => setSelectedMetric(value as CallMetric)}
      isLoading={isLoadingCallMetricAnalytics}
      benchmark={benchmark}
    />
  );
};

export default CallMetricChart;
