import { useCallback, useState } from 'react';
import { useGetScorecardTemplatesForSelectQuery, useUpdateOrganizationSettingsMutation } from '../../../../../services';
import { getAIScorecardTemplateOptions } from '../../../../../utils';
import { Typography, TypographySize, Select, findOptionByValue, AlertType } from '../../../../shared';
import OrgTabSectionTitle from './OrgTabSectionTitle';
import { useHandleApiResponse, useToast } from '../../../../../hooks';

const ERROR_MSG = 'Failed to save default AI scorecard';
const SELECT_AI_SCORECARD_WIDTH = 280;

interface DefaultAIScorecardProps {
  defaultScorecardTemplateId?: string;
}

const DefaultAIScorecard = ({ defaultScorecardTemplateId }: DefaultAIScorecardProps) => {
  const { data: scorecardTemplates = [], isLoading: isLoadingScorecardTemplates } =
    useGetScorecardTemplatesForSelectQuery({});
  const [updateOrganizationSettings] = useUpdateOrganizationSettingsMutation();

  const handleApiResponse = useHandleApiResponse();
  const { showToast } = useToast();

  const [scorecardTemplateIdInput, setScorecardTemplateIdInput] = useState<string | undefined>(
    defaultScorecardTemplateId
  );
  const [error, setError] = useState<boolean>(false);

  const scorecardTemplateOptions = getAIScorecardTemplateOptions(scorecardTemplates);
  const selectedScorecardTemplateOption = findOptionByValue(scorecardTemplateOptions, scorecardTemplateIdInput);

  const handleUpdateDefaultScorecard = useCallback(
    async (newAIScorecardTemplate: string) => {
      setError(false);
      // For immediate user feedback till the update succeeds
      setScorecardTemplateIdInput(newAIScorecardTemplate);

      try {
        const response = await updateOrganizationSettings({
          defaultScorecardTemplateId: newAIScorecardTemplate,
        });
        handleApiResponse({
          response,
          errorMsg: ERROR_MSG,
          onError: () => setError(true),
        });
      } catch (error) {
        console.error(`${ERROR_MSG}: ${error}`);
        setError(true);
        showToast({ message: ERROR_MSG, type: AlertType.ERROR });
      }
    },
    [updateOrganizationSettings, handleApiResponse, showToast]
  );
  return (
    <div className="flex flex-col gap-2">
      <OrgTabSectionTitle
        title="AI scorecard"
        iconTooltip="The selected AI scorecard will be the default for all new prospects."
      />
      <div className="flex flex-1 justify-between gap-2">
        <Typography size={TypographySize.H5}>Default AI scorecard</Typography>
        <Select
          loading={isLoadingScorecardTemplates}
          options={scorecardTemplateOptions}
          selected={selectedScorecardTemplateOption}
          placeholder="Select AI scorecard"
          width={SELECT_AI_SCORECARD_WIDTH}
          error={error}
          onChange={(newValue) => handleUpdateDefaultScorecard(newValue as string)}
        />
      </div>
    </div>
  );
};

export default DefaultAIScorecard;
