import { DatePickerRange, SelectOption } from '../components';
import { AppUser, Team } from '../types';
import { formatDisplayedPhoneNumber, snakeCaseToLabel } from './string.utils';

/**
 * Parses a required value into a SelectOption shape (label and value).
 */
export const parseRequiredSelectOption = (value: string): SelectOption => ({ label: snakeCaseToLabel(value), value });

/**
 * Creates user options from user data.
 */
export const getUserOptions = (userData?: { users: AppUser[]; phoneNumbers?: string[] }) => {
  const mappedUsers = userData?.users.map(({ id, name = '' }) => ({ value: id, label: name })) || [];
  const mappedPhoneNumbers = (userData?.phoneNumbers || []).map((phoneNumber) => ({
    value: phoneNumber,
    label: formatDisplayedPhoneNumber(phoneNumber),
  }));
  return mappedUsers.concat(mappedPhoneNumbers);
};

/**
 * Gets the date range parameter from the start and end dates.
 */
export const getDateRangeParam = (range: DatePickerRange): string => `${range.start.getTime()}-${range.end.getTime()}`;

/**
 * Gets the team options with the user's teams first.
 */
export const getTeamsOptions = (orgTeams: Team[], userTeams: Team[]): SelectOption[] => {
  return orgTeams
    .map(({ id, name, color }) => {
      const isUserTeam = userTeams.some((userTeam) => userTeam.id === id);
      return {
        value: id,
        label: `${name}${isUserTeam ? ' (your team)' : ''}`,
        color,
        isUserTeam,
      };
    })
    .sort(({ isUserTeam: a }, { isUserTeam: b }) => (b ? 1 : 0) - (a ? 1 : 0));
};
