import { DatePickerRange } from '../components';
import { AverageByPeriod } from './analytics.types';
import { ProspectType } from './prospect.types';

/**
 * Enum to define the keys used for activity filters in the URL and state.
 * Represents the true keys used in the API.
 */
export enum ActivityFilterKeys {
  DATE_CREATED = 'createdDate',
  PROSPECT = 'personaId',
  PROSPECT_ACCOUNT = 'accountName',
  SEARCH = 'search',
  TAGS = 'tags',
}

/**
 * Enum to define the keys used for call filters in the URL and state.
 * Represents the true keys used in the API.
 */
export enum ReviewFilterKeys {
  CALL_DATE = 'startTime',
  CATEGORY = 'types',
  INCOMING_PHONE_NUMBER = 'incomingPhoneNumber',
  USER = 'userId',
  FLAGGED = 'isFlagged',
  PROSPECT = 'personaId',
  TAGS = 'tags',
  TEAM = 'teamIds',
}

/**
 * Enum to define the keys used for analytics filters in the URL and state.
 * Represents the true keys used in the API.
 */
export enum AnalyticsFilterKeys {
  DATE_RANGE = 'dateRange',
  CALLERS = 'callers',
  USER = 'userId',
  PROSPECT = 'prospectIds',
  TAGS = 'tags',
  AVERAGE_BY = 'groupBy',
  TEAM = 'teamIds',
}

/** Represents the keys used for various filter types. */
export type FilterKeys = ActivityFilterKeys | ReviewFilterKeys | AnalyticsFilterKeys;

/**
 * Type definition for the filters used in the practice page.
 * TODO: Replace the existing payload definition with this once the payload accepts the date as a single value.
 */
export type ActivityFilters = {
  [ActivityFilterKeys.DATE_CREATED]?: DatePickerRange;
  [ActivityFilterKeys.PROSPECT]?: string[];
  [ActivityFilterKeys.PROSPECT_ACCOUNT]?: string[];
  [ActivityFilterKeys.SEARCH]?: string;
  [ActivityFilterKeys.TAGS]?: string[];
};

/**
 * Type definition for the filters used in the call page.
 * TODO: Replace the existing payload definition with this once the payload accepts the date as a single value.
 */
export type ReviewFilters = {
  [ReviewFilterKeys.CALL_DATE]?: DatePickerRange;
  [ReviewFilterKeys.CATEGORY]?: ProspectType[];
  [ReviewFilterKeys.INCOMING_PHONE_NUMBER]?: string[];
  [ReviewFilterKeys.USER]?: string[];
  [ReviewFilterKeys.FLAGGED]?: boolean;
  [ReviewFilterKeys.PROSPECT]?: string[];
  [ReviewFilterKeys.TAGS]?: string[];
  [ReviewFilterKeys.TEAM]?: string[];
};

/**
 * Type definition for the filters used in the analytics page.
 * TODO: Replace the existing payload definition with this once the payload accepts the date as a single value.
 */
export type AnalyticsFilters = {
  [AnalyticsFilterKeys.DATE_RANGE]?: DatePickerRange;
  [AnalyticsFilterKeys.CALLERS]?: string[];
  [AnalyticsFilterKeys.USER]?: string;
  [AnalyticsFilterKeys.PROSPECT]?: string[];
  [AnalyticsFilterKeys.TAGS]?: string[];
  [AnalyticsFilterKeys.AVERAGE_BY]?: AverageByPeriod;
  [AnalyticsFilterKeys.TEAM]?: string[];
};

/** Combines the filter structures from different contexts. */
export type Filters = ActivityFilters | ReviewFilters | AnalyticsFilters;

/** Defines the possible value types for filters. */
export type FilterValues = boolean | string | string[] | DatePickerRange;
