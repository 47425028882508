import { ComponentSize } from '../../../types';
import { TypographySize } from '../Typography';

/** Mapping of avatar sizes to corresponding width values. */
export const AVATAR_SIZE_TO_WIDTH: Record<ComponentSize, number> = {
  [ComponentSize.X_SMALL]: 24,
  [ComponentSize.SMALL]: 32,
  [ComponentSize.MEDIUM]: 48,
  [ComponentSize.LARGE]: 80,
};

/** Mapping of avatar sizes to corresponding typography sizes. */
export const AVATAR_TO_TYPOGRAPHY_SIZE: Record<ComponentSize, TypographySize> = {
  [ComponentSize.X_SMALL]: TypographySize.CAPTION,
  [ComponentSize.SMALL]: TypographySize.H5,
  [ComponentSize.MEDIUM]: TypographySize.H2,
  [ComponentSize.LARGE]: TypographySize.H2,
};

/** Mapping of color names to background color classes. */
export const AVATAR_BG_COLOR_VALUES = {
  'dark-green': 'bg-secondary',
  green: 'bg-primary',
  'dark-blue': 'bg-accent',
  blue: 'bg-blue-200',
  gray: 'bg-gray-800',
};
