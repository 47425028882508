import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../../constants';
import { useAppSelector } from '../../../../../hooks';
import { OrganizationStatus, TextColor } from '../../../../../types';
import { TextButton, Typography, TypographySize, TypographyWeight } from '../../../../shared';
import ItemWrapper from '../ItemWrapper';

const ScorecardTab = () => {
  const navigate = useNavigate();

  const { organization } = useAppSelector((state) => state.auth);
  const isTrialOrg = organization?.status === OrganizationStatus.TRIAL;

  // Close the Create modal and navigate to the Scorecard Templates page.
  const handleCreate = () => {
    navigate(AppRoutes.SCORECARD_TEMPLATES);
  };

  return (
    <div className="flex flex-col gap-2 rounded-2xl bg-base-100 p-4">
      <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
        Create and manage scorecards
      </Typography>
      <ItemWrapper fullWidth>
        <div className="flex justify-between">
          <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
            Build scorecards for evaluating simulations.
          </Typography>
          <TextButton text="Open scorecards" onClick={handleCreate} disabled={isTrialOrg} />
        </div>
      </ItemWrapper>
    </div>
  );
};

export default ScorecardTab;
