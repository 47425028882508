import { Dispatch, SetStateAction, useCallback } from 'react';
import { PUBLIC_PROSPECT_TYPE_OPTIONS } from '../../../constants';
import { ProspectType } from '../../../types';
import { findOptionByValue, Select } from '../../shared';

interface SimulationPurposeSelectorProps {
  filterProspectTypes: (type: ProspectType) => boolean;
  onChange: () => void;
  setNewProspectPurpose: Dispatch<SetStateAction<ProspectType | undefined>>;
  newProspectPurpose?: ProspectType;
}

const SimulationPurposeSelector = ({
  filterProspectTypes,
  onChange,
  setNewProspectPurpose,
  newProspectPurpose,
}: SimulationPurposeSelectorProps) => {
  const options = PUBLIC_PROSPECT_TYPE_OPTIONS.filter(({ value }) => filterProspectTypes(value));

  const onCategoryChange = useCallback(
    (newValue?: string) => {
      setNewProspectPurpose(newValue as ProspectType);
      onChange();
    },
    [setNewProspectPurpose, onChange]
  );

  return (
    <Select
      clearable
      placeholder="Select category"
      options={options}
      selected={findOptionByValue(options, newProspectPurpose)}
      onChange={onCategoryChange}
    />
  );
};

export default SimulationPurposeSelector;
