import { ReactNode } from 'react';
import { Icon, IconComponent } from '../Icons';
import { TypographyComponent } from '../Typography';

export type AccordionItem = {
  /** Unique identifier for accordion items. */
  id: string;
  /** Title for accordion item */
  title: string | TypographyComponent;
  /** Additional className for accordion item */
  className?: string;
  /** Content for accordion item */
  content?: ReactNode;
  /** Whether the accordion item is disabled */
  disabled?: boolean;
  /** Icon for accordion item */
  icon?: Icon | IconComponent;
};

export enum AccordionType {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export interface AccordionProps {
  items: AccordionItem[];
  className?: string;
  type?: AccordionType;
}
