import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { PUBLIC_PROSPECT_TYPES, SELECT_SEARCH_THRESHOLD } from '../../../constants';
import { useGetPracticeProspectsForSelectQuery } from '../../../services';
import { ProspectType } from '../../../types';
import { conditionalObject, parseProspectToOption } from '../../../utils';
import { findOptionByValue, OrgSelector, Select } from '../../shared';

interface SimulationTemplateSelectorProps {
  isSuperAdmin: boolean;
  filterProspectTypes: (type: ProspectType) => boolean;
  onChange: () => void;
  setTemplateOrgId: Dispatch<SetStateAction<string | undefined>>;
  setTemplateProspectId: Dispatch<SetStateAction<string | undefined>>;
  templateOrgId?: string;
  templateProspectId?: string;
}

const SimulationTemplateSelector = ({
  isSuperAdmin,
  filterProspectTypes,
  onChange,
  setTemplateOrgId,
  setTemplateProspectId,
  templateOrgId,
  templateProspectId,
}: SimulationTemplateSelectorProps) => {
  const [templateProspectSearchValue, setTemplateProspectSearchValue] = useState('');

  // If the user is a super-admin, they need to select an organization before selecting a prospect.
  const isProspectSelectDisabled = isSuperAdmin && !templateOrgId;
  const types = PUBLIC_PROSPECT_TYPES.filter(filterProspectTypes);

  // Get the prospects for the selected organization.
  // If the user is not a super-admin, they can only select prospects from their own organization.
  const { data: prospects = [], isLoading: isProspectsLoading } = useGetPracticeProspectsForSelectQuery(
    { ...conditionalObject(!!templateOrgId, { orgId: templateOrgId }), types },
    { skip: isProspectSelectDisabled }
  );

  const prospectOptions = useMemo(() => prospects.map((p) => parseProspectToOption(p)), [prospects]);

  const isProspectSearchEnabled = prospectOptions.length > SELECT_SEARCH_THRESHOLD;
  const prospectSearchProps = isProspectSearchEnabled
    ? { searchValue: templateProspectSearchValue, setSearchValue: setTemplateProspectSearchValue }
    : undefined;

  const onOrgChange = useCallback(
    (newValue?: string) => {
      setTemplateOrgId(newValue);
      // Reset the prospect ID when the organization changes.
      setTemplateProspectId(undefined);
      onChange();
    },
    [setTemplateOrgId, setTemplateProspectId, onChange]
  );

  const onProspectChange = useCallback(
    (newValue?: string) => {
      setTemplateProspectId(newValue);
      onChange();
    },
    [setTemplateProspectId, onChange]
  );

  return (
    <div className="flex flex-col gap-2">
      {isSuperAdmin && <OrgSelector clearable selectedOrgId={templateOrgId} onChange={onOrgChange} />}
      <Select
        clearable
        disabled={isProspectSelectDisabled}
        loading={isProspectsLoading}
        placeholder="Select simulation"
        options={prospectOptions}
        searchProps={prospectSearchProps}
        selected={findOptionByValue(prospectOptions, templateProspectId)}
        onChange={onProspectChange}
      />
    </div>
  );
};

export default SimulationTemplateSelector;
