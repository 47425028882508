import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../constants';
import { useAppSelector } from '../../../hooks';
import { Permissions, ProspectPageTabs, ProspectScenario, ProspectType } from '../../../types';
import { Dialog } from '../../shared';
import SimulationScenarioStep from './SimulationScenarioStep';
import SimulationTypeStep, { SimulationDesignerOption } from './SimulationTypeStep';

const DESIGNER_MODAL_WIDTH = 485;

enum SimulationDesignerStep {
  TYPE = 'TYPE',
  SCENARIO = 'SCENARIO',
}

interface SimulationDesignerModalProps {
  setVisitedTabs: Dispatch<SetStateAction<Set<ProspectPageTabs>>>;
}

const SimulationDesignerModal = ({ setVisitedTabs }: SimulationDesignerModalProps) => {
  const navigate = useNavigate();

  const { user, organization } = useAppSelector((state) => state.auth);
  const permissions = user?.permissions || [];
  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);

  const [currentStep, setCurrentStep] = useState<SimulationDesignerStep>(SimulationDesignerStep.TYPE);

  const [newProspectPurpose, setNewProspectPurpose] = useState<ProspectType>();
  const [newScenario, setNewScenario] = useState<ProspectScenario>();

  const [targetOrgId, setTargetOrgId] = useState(isSuperAdmin ? undefined : organization?.id);
  // This state is initialized here so it would persist between steps if the user decides to go back.
  const [selectedDesignerOption, setSelectedDesignerOption] = useState(SimulationDesignerOption.TEMPLATE);

  // Exits designer mode by navigating to the practice page,
  // since the designer modal only opens in the /prospect page.
  const onCancel = useCallback(() => {
    navigate(AppRoutes.PRACTICE);
  }, [navigate]);

  return (
    <Dialog isOpen title="Simulation designer" width={DESIGNER_MODAL_WIDTH} onClose={onCancel}>
      {currentStep === SimulationDesignerStep.TYPE && (
        <SimulationTypeStep
          isSuperAdmin={isSuperAdmin}
          newProspectPurpose={newProspectPurpose}
          targetOrgId={targetOrgId}
          selectedDesignerOption={selectedDesignerOption}
          goToNextStep={() => setCurrentStep(SimulationDesignerStep.SCENARIO)}
          onCancel={onCancel}
          setNewProspectPurpose={setNewProspectPurpose}
          setTargetOrgId={setTargetOrgId}
          setSelectedDesignerOption={setSelectedDesignerOption}
          setVisitedTabs={setVisitedTabs}
        />
      )}
      {currentStep === SimulationDesignerStep.SCENARIO && (
        <SimulationScenarioStep
          targetOrgId={targetOrgId}
          newProspectPurpose={newProspectPurpose}
          newScenario={newScenario}
          goToPrevStep={() => setCurrentStep(SimulationDesignerStep.TYPE)}
          setNewScenario={setNewScenario}
        />
      )}
    </Dialog>
  );
};

export default SimulationDesignerModal;
